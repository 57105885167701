import React from 'react';
import useStateWithLocalStorage from './useStateWithLocalStorage';
import './ImageStyleChooser.css'

const THEME_1 = 1;
const THEME_2 = 2;
const THEME_3 = 3;
const THEME_4 = 4;
const THEME_5 = 5;
const THEME_6 = 6;
const THEME_7 = 7;
const THEME_8 = 8;
const THEME_9 = 9;
const THEME_10 = 10;
const THEME_11 = 11;
const THEME_12 = 12;

const SHAPE_RECT = 1;
const SHAPE_SQUARE = 2;

function ImageStyleChooser(props) {

  const [imageStyle, setImageStyle] = useStateWithLocalStorage(
    'imageStyle'
  );

  const [imageShape, setImageShape] = useStateWithLocalStorage(
    'imageShape'
  );

  const onClickThemeChooser = (theme) => {
    console.log('onClickThemeChooser: ' + theme);
    setImageStyle(theme);
    props.onChange();
  }

  const onClickShape = (shape) => {
    setImageShape(shape);
    props.onChange();
  }

  const imageStyleValue = parseInt(imageStyle ? imageStyle : THEME_3);

  var theme1Border = imageStyleValue === THEME_1 ? 'Border' : 'NoBorder';
  var theme2Border = imageStyleValue === THEME_2 ? 'Border' : 'NoBorder';
  var theme3Border = imageStyleValue === THEME_3 ? 'Border' : 'NoBorder';
  var theme4Border = imageStyleValue === THEME_4 ? 'Border' : 'NoBorder';
  var theme5Border = imageStyleValue === THEME_5 ? 'Border' : 'NoBorder';
  var theme6Border = imageStyleValue === THEME_6 ? 'Border' : 'NoBorder';
  var theme7Border = imageStyleValue === THEME_7 ? 'Border' : 'NoBorder';
  var theme8Border = imageStyleValue === THEME_8 ? 'Border' : 'NoBorder';
  var theme9Border = imageStyleValue === THEME_9 ? 'Border' : 'NoBorder';
  var theme10Border = imageStyleValue === THEME_10 ? 'Border' : 'NoBorder';
  var theme11Border = imageStyleValue === THEME_11 ? 'Border' : 'NoBorder';
  var theme12Border = imageStyleValue === THEME_12 ? 'Border' : 'NoBorder';

  const imageShapeValue = parseInt(imageShape ? imageShape : SHAPE_RECT);

  var shape1Border = imageShapeValue === SHAPE_RECT ? 'Border' : 'NoBorder';
  var shape2Border = imageShapeValue === SHAPE_SQUARE ? 'Border' : 'NoBorder';

  var fields =
  (<div>
    <table style={{width:'100%'}}>
      <tbody>
        <tr>
          <td colSpan="3">
            COLOR SCHEME
          </td>
        </tr>
        <tr>
          <td>
            <button
              className={theme2Border}
              onClick={(event)=>{event.preventDefault();onClickThemeChooser(THEME_2);}}>
              <div className="ThemeBox ThemeBox2">DARK</div>
            </button>
          </td>
          <td>
            <button
              className={theme1Border}
              onClick={(event)=>{event.preventDefault();onClickThemeChooser(THEME_1);}}>
              <div className="ThemeBox ThemeBox1">ASH</div>
            </button>
          </td>
          <td>
            <button
              className={theme3Border}
              onClick={(event)=>{event.preventDefault();onClickThemeChooser(THEME_3);}}>
              <div className="ThemeBox ThemeBox3">LIGHT</div>
            </button>
          </td>
          </tr>
          <tr>
          <td>
            <button
              className={theme4Border}
              onClick={(event)=>{event.preventDefault();onClickThemeChooser(THEME_4);}}>
              <div className="ThemeBox ThemeBox4">PURPLE</div>
            </button>
          </td>
          <td>
            <button
              className={theme5Border}
              onClick={(event)=>{event.preventDefault();onClickThemeChooser(THEME_5);}}>
              <div className="ThemeBox ThemeBox5">PINK</div>
            </button>
          </td>
          <td>
            <button
              className={theme6Border}
              onClick={(event)=>{event.preventDefault();onClickThemeChooser(THEME_6);}}>
              <div className="ThemeBox ThemeBox6">GREEN</div>
            </button>
          </td>
        </tr>
        <tr>
        <td>
          <button
            className={theme7Border}
            onClick={(event)=>{event.preventDefault();onClickThemeChooser(THEME_7);}}>
            <div className="ThemeBox ThemeBox7">ORANGE</div>
          </button>
        </td>
        <td>
          <button
            className={theme8Border}
            onClick={(event)=>{event.preventDefault();onClickThemeChooser(THEME_8);}}>
            <div className="ThemeBox ThemeBox8">SKY</div>
          </button>
        </td>
        <td>
          <button
            className={theme9Border}
            onClick={(event)=>{event.preventDefault();onClickThemeChooser(THEME_9);}}>
            <div className="ThemeBox ThemeBox9">LEMON</div>
          </button>
        </td>
      </tr>
      <tr>
      <td>
        <button
          className={theme10Border}
          onClick={(event)=>{event.preventDefault();onClickThemeChooser(THEME_10);}}>
          <div className="ThemeBox ThemeBox10">BLUE</div>
        </button>
      </td>
      <td>
        <button
          className={theme11Border}
          onClick={(event)=>{event.preventDefault();onClickThemeChooser(THEME_11);}}>
          <div className="ThemeBox ThemeBox11">CORAL</div>
        </button>
      </td>
      <td>
        <button
          className={theme12Border}
          onClick={(event)=>{event.preventDefault();onClickThemeChooser(THEME_12);}}>
          <div className="ThemeBox ThemeBox12">FOREST</div>
        </button>
      </td>
    </tr>
          </tbody>
        </table>
        <table style={{width:'100%'}}>
          <tbody>
        <tr>
          <td colSpan="2">
            SHAPE
          </td>
        </tr>
        <tr>
          <td>
            <button
              className={shape1Border}
              onClick={(event)=>{event.preventDefault();onClickShape(SHAPE_RECT);}}>
              <div className="ThemeBox ThemeBox2">RECTANGLE</div>
            </button>
          </td>
          <td>
            <button
              className={shape2Border}
              onClick={(event)=>{event.preventDefault();onClickShape(SHAPE_SQUARE);}}>
              <div className="ThemeBox ThemeBox2">SQUARE</div>
            </button>
          </td>
        </tr>
      </tbody>
    </table></div>
  );

  return (
    <div className="ImageStyleChooser">
      {fields}
    </div>
  );
}

export default ImageStyleChooser;
