import React from 'react';
import useStateWithLocalStorage from './useStateWithLocalStorage';
import Trash from './img/trash.png';
import ActionDropDown from './ActionDropDown';

import './Details.css'

function Details(props) {
  const [details, setDetails] = useStateWithLocalStorage(
    'details'
  );
  const [blinds, setBlinds] = useStateWithLocalStorage(
    'blinds'
  );

  const onChangeDetails = (event) => {
    setDetails(event.target.value);
    props.onTextChange();
  };

  const onAppendText = (text) => {
    setDetails(details + text);
    props.onTextChange();
  };

  const clearDetails = () => {
    setDetails('');
    props.onTextChange();
  };

  const onChangeBlinds = (event) => {
    setBlinds(event.target.value);
    props.onTextChange();
  };

  const clearBlinds = () => {
    setBlinds('');
    props.onTextChange();
  };

  var textInputFields = (
    <table><tbody>
      <tr><td>
      <div><span>BLINDS</span></div>
      <table style={{marginRight:'5px'}}><tbody>
      <tr>
        <td>
          <input style={{textAlign:'left'}} className='BlindsInputField' value={blinds} type="text" onChange={onChangeBlinds} ref={props.blindsInputRef}/>
        </td>
        <td style={{verticalAlign:'bottom'}}><button className='TrashButton' onClick={clearBlinds}>
          <img className='TrashImage' src={Trash} alt="CLEAR"/>
        </button></td>
      </tr>
      </tbody></table>

      </td></tr>
      <tr><td>
      <div style={{marginTop:'4px'}}><span>ACTION</span></div>
      <ActionDropDown
      boardCard1={props.boardCard1}
      boardCard2={props.boardCard2}
      boardCard3={props.boardCard3}
      boardCard4={props.boardCard4}
      boardCard5={props.boardCard5}

      player1Name={props.player1Name}
      player1Position={props.player1Position}
      player1Card1={props.player1Card1}
      player1Card2={props.player1Card2}

      player2Name={props.player2Name}
      player2Position={props.player2Position}
      player2Card1={props.player2Card1}
      player2Card2={props.player2Card2}

      player3Enabled={props.player3Enabled}
      player3Name={props.player3Name}
      player3Position={props.player3Position}
      player3Card1={props.player3Card1}
      player3Card2={props.player3Card2}

      appendText={onAppendText}
      />
      </td></tr>

      <tr><td>
      <div style={{marginTop:'4px'}}><span>HAND DETAILS</span></div>
      <textarea style={{marginTop:'3px'}} className='InputArea' value={details} onChange={onChangeDetails} ref={props.detailsInputRef}/>
      <br/>
      <button className='TrashButton' onClick={clearDetails}>
        <img className='TrashImage' src={Trash} alt="CLEAR"/>
      </button>
    </td></tr>

    </tbody></table>
  );

  return (
    <div>
      <div className="BoxTitle">HAND DESCRIPTION</div>
      <div className='PlayerForm'>
        {textInputFields}
      </div>
    </div>
  );
}

export default Details;
