import React, { useState } from 'react';
import * as ReactDOM from 'react-dom';
import useStateWithLocalStorage from './useStateWithLocalStorage';
import Board from './Board';
import Commands from './Commands';
import Details from './Details';
import Event from './Event';
import PlayerForm from './PlayerForm';
import CardChooser from './CardChooser';
import TrashButton from './TrashButton';
import ImageStyleChooser from './ImageStyleChooser';
import ShareImage from './ShareImage';
import AddPlayer from './AddPlayer';
import { calculateEquity } from 'poker-odds'
import Logo from './img/PokerHandPostBadge_logoweb.png';
import TwitterLogo from './img/twitter.png';
import FacebookLogo from './img/facebook.png';
import InstagramLogo from './img/Instagram.png';
import DownloadIcon from './img/DownloadIcon.png';
// import {toToken, fromToken} from './localStorageTokenizer';
import './App.css';

const prefix = 'https://assets.pokerhandpost.com';
const canvasWidth = 628;

function noop() {}

if (process.env.NODE_ENV !== 'development') {
  console.log = noop;
//  console.warn = noop;
//  console.error = noop;
  if (window.location.protocol !== 'https:') {
    window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
  }
}

// const search = window.location.search;
// const params = new URLSearchParams(search);
// const token = params.get('share');
var handId = undefined;

if (!handId) {
  handId = Math.round(Math.random() * 16 * (new Date()).getTime());
  console.log('New Hand ID = ' + handId);
}

/*
if (token) {
  console.log('parameter = ' + token);
  const decodedToken = fromToken(token);

  if (decodedToken) {
    localStorage.clear();
    const newState = JSON.parse(decodedToken);
    console.log('token state = ' + JSON.stringify(newState));

    for (const key in newState) {
      const value = newState[key];
      localStorage.setItem(key, newState[key]);
      console.log('setItem: ' + key + ' = ' + value);
    }
  }
}
*/

function App() {  
  const [imageData, setImageData] = useState(undefined);
//  const [linkToken, setLinkToken] = useState(undefined);

  const [cardChooserVisible, setCardChooserVisible] = useState(false);
  const [cardChooserKey, setCardChooserKey] = useState(undefined);
  const [odds, setOdds] = useState(undefined);
  const [oddsReady, setOddsReady] = useState(false);

  const [player1Name, setPlayer1Name] =
    useState(localStorage.getItem('player1:playerName'));
  const [player1Chips, setPlayer1Chips] =
    useState(localStorage.getItem('player1:playerChips'));
  const [player1Position, setPlayer1Position] =
    useState(localStorage.getItem('player1:playerPosition'));

  const [player2Name, setPlayer2Name] =
    useState(localStorage.getItem('player2:playerName'));
  const [player2Chips, setPlayer2Chips] =
    useState(localStorage.getItem('player2:playerChips'));
  const [player2Position, setPlayer2Position] =
    useState(localStorage.getItem('player2:playerPosition'));

  const [player3Enabled, setPlayer3Enabled] =
    useStateWithLocalStorage('player3Enabled');
  const [player3Name, setPlayer3Name] =
    useState(localStorage.getItem('player3:playerName'));
  const [player3Chips, setPlayer3Chips] =
    useState(localStorage.getItem('player3:playerChips'));
  const [player3Position, setPlayer3Position] =
    useState(localStorage.getItem('player3:playerPosition'));

  const [detailsText, setDetailsText] =
    useState(localStorage.getItem('details'));
  const [eventText, setEventText] =
    useState(localStorage.getItem('event'));
  const [locationText, setLocationText] =
    useState(localStorage.getItem('location'));
  const [blinds, setBlinds] =
    useState(localStorage.getItem('blinds'));
  const [imageStyle, setImageStyle] = useState(localStorage.getItem('imageStyle'));
  const [imageShape, setImageShape] = useState(localStorage.getItem('imageShape'));

  let clickMap = []
  const addClickMap = (entry) => {
    console.log('addClickMap: ' + JSON.stringify(entry))
    clickMap.push(entry);
  };
  const resetClickMap = () => {
    clickMap = [];
  }

  const eventInputRef = React.useRef(null);
  const locationInputRef = React.useRef(null);
  const player1DisplayNameInputRef = React.useRef(null);
  const player1DisplayChipsInputRef = React.useRef(null);
  const player2DisplayNameInputRef = React.useRef(null);
  const player2DisplayChipsInputRef = React.useRef(null);
  const player3DisplayNameInputRef = React.useRef(null);
  const player3DisplayChipsInputRef = React.useRef(null);
  const blindsInputRef = React.useRef(null);
  const detailsInputRef = React.useRef(null);

  const downloadLinkRef = React.useRef(null);
  const downloadFilename = new Date().getTime() + '.png';
  const downloadLink = (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a download={downloadFilename} ref={downloadLinkRef} href={'#'} style={{color:'#FFFFFF', textDecoration:'none'}}>
      <img alt={'Download Link'} style={{height:'35px', marginHeight:'0'}} src={DownloadIcon}/>
    </a>
  );

  const editSectionRef = React.createRef();
  const focusEdit = () => {
    editSectionRef.current.scrollIntoView();
  }
/*
  const updateToken = () => {
    const json = JSON.stringify(localStorage);
    const token = toToken(json);
    console.log('updateToken: ' + token);
    setLinkToken(token);
  }
*/
  const updateImageData = (data) => {
    console.log('updateImageData: ' + data.length);
    setImageData(data);
  }

  const calculateOdds = () => {

    const boardCard1 = localStorage.getItem('board:card1');
    const boardCard2 = localStorage.getItem('board:card2');
    const boardCard3 = localStorage.getItem('board:card3');
    const boardCard4 = localStorage.getItem('board:card4');
    const boardCard5 = localStorage.getItem('board:card5');

    const player1Card1 = localStorage.getItem('player1:playerCard1');
    const player1Card2 = localStorage.getItem('player1:playerCard2');

    const player2Card1 = localStorage.getItem('player2:playerCard1');
    const player2Card2 = localStorage.getItem('player2:playerCard2');

    const player3Enabled = localStorage.getItem('player3Enabled');
    const player3Card1 = localStorage.getItem('player3:playerCard1');
    const player3Card2 = localStorage.getItem('player3:playerCard2');

    const hands = [[player1Card1, player1Card2], [player2Card1, player2Card2]];

    if (!('false' === player3Enabled || !player3Enabled)) {
      console.log('odds include player 3');
      hands.push([player3Card1, player3Card2]);
    }

    const preFlopBoard = [];
    const flopBoard = [boardCard1, boardCard2, boardCard3];
    const turnBoard = [boardCard1, boardCard2, boardCard3, boardCard4];
    const riverBoard = [boardCard1, boardCard2, boardCard3, boardCard4, boardCard5];
    const iterations = 100000 // optional
    const exhaustive = false // optional

    var preFlopOdds = [];

    var flopOdds = [];
    var turnOdds = [];
    var riverOdds = [];

    try {
      preFlopOdds = calculateEquity(hands, preFlopBoard, iterations, exhaustive);
    } catch (e) {}
    console.log("PREFLOP ODDS: " + JSON.stringify(preFlopOdds));

    try {
      flopOdds = calculateEquity(hands, flopBoard, iterations, exhaustive);
    } catch (e) {}
    console.log("FLOP ODDS: " + JSON.stringify(flopOdds));

    try {
      turnOdds = calculateEquity(hands, turnBoard, iterations, exhaustive);
    } catch (e) {}
    console.log("TURN ODDS: " + JSON.stringify(turnOdds));

    try {
      riverOdds = calculateEquity(hands, riverBoard, iterations, exhaustive);
    } catch (e) {}
    console.log("RIVER ODDS: " + JSON.stringify(riverOdds));

    setOdds({
      preFlop: preFlopOdds,
      flop: flopOdds,
      turn: turnOdds,
      river: riverOdds
    });
  };

  const onDisablePlayer3 = () => {
    setPlayer3Enabled(false);
    updateCards();
  };
  const updateCards = () => {
    console.log("updateCards - timer start");
    setTimeout(() => {
      console.log("updateCards - timer expired");

      let delta = false;

      if (boardCard1 !== localStorage.getItem('board:card1')) {
        setBoardCard1(localStorage.getItem('board:card1'));
        delta = true;
      }
      if (boardCard2 !== localStorage.getItem('board:card2')) {
        setBoardCard2(localStorage.getItem('board:card2'));
        delta = true;
      }
      if (boardCard3 !== localStorage.getItem('board:card3')) {
        setBoardCard3(localStorage.getItem('board:card3'));
        delta = true;
      }
      if (boardCard4 !== localStorage.getItem('board:card4')) {
        setBoardCard4(localStorage.getItem('board:card4'));
        delta = true;
      }
      if (boardCard5 !== localStorage.getItem('board:card5')) {
        setBoardCard5(localStorage.getItem('board:card5'));
        delta = true;
      }
      if (player1Card1 !== localStorage.getItem('player1:playerCard1')) {
        setPlayer1Card1(localStorage.getItem('player1:playerCard1'));
        delta = true;
      }
      if (player1Card2 !== localStorage.getItem('player1:playerCard2')) {
        setPlayer1Card2(localStorage.getItem('player1:playerCard2'));
        delta = true;
      }
      if (player2Card1 !== localStorage.getItem('player2:playerCard1')) {
        setPlayer2Card1(localStorage.getItem('player2:playerCard1'));
        delta = true;
      }
      if (player2Card2 !== localStorage.getItem('player2:playerCard2')) {
        setPlayer2Card2(localStorage.getItem('player2:playerCard2'));
        delta = true;
      }
      if (player3Card1 !== localStorage.getItem('player3:playerCard1')) {
        setPlayer3Card1(localStorage.getItem('player3:playerCard1'));
        delta = true;
      }
      if (player3Card2 !== localStorage.getItem('player3:playerCard2')) {
        setPlayer3Card2(localStorage.getItem('player3:playerCard2'));
        delta = true;
      }
      if (player3Enabled !== localStorage.getItem('player3Enabled')) {
        setPlayer3Enabled(localStorage.getItem('player3Enabled'));
        delta = true;
      }

      if (delta || !odds) {
        setOddsReady(false);

        setTimeout(() => {
          console.log("updateCards - calculating odds...");
          calculateOdds();
          setOddsReady(true);
        }, 100);

/*
        setTimeout(() => {
          console.log("updateCards - updating token...");
          updateToken();
        }, 100);
        */
      }
    }, 10);
  };

  if (!odds) {
    console.log("odds undefined - updating cards...");
    updateCards();
  }

  const onCardChosen = (event) => {
    console.log("Card Chosen Event");
    setCardChooserVisible(false);
    document.removeEventListener('cardChosen', onCardChosen);
    updateCards();
  };

  const onClickCardChooser = (key) => {
    console.log("onClickCardChooser: " + key);
    setCardChooserKey(key);
    setCardChooserVisible(true);
    document.addEventListener('cardChosen', onCardChosen);
  };

  const onTextChange = () => {
    console.log("onTextChange");

    setTimeout(() => {
      setPlayer1Name(localStorage.getItem('player1:playerName'));
      setPlayer1Chips(localStorage.getItem('player1:playerChips'));
      setPlayer1Position(localStorage.getItem('player1:playerPosition'));

      setPlayer2Name(localStorage.getItem('player2:playerName'));
      setPlayer2Chips(localStorage.getItem('player2:playerChips'));
      setPlayer2Position(localStorage.getItem('player2:playerPosition'));

      setPlayer3Enabled(localStorage.getItem('player3Enabled'));
      setPlayer3Name(localStorage.getItem('player3:playerName'));
      setPlayer3Chips(localStorage.getItem('player3:playerChips'));
      setPlayer3Position(localStorage.getItem('player3:playerPosition'));

      setDetailsText(localStorage.getItem('details'));
      setEventText(localStorage.getItem('event'));
      setLocationText(localStorage.getItem('location'));
      setBlinds(localStorage.getItem('blinds'));

      setImageStyle(localStorage.getItem('imageStyle'));
      setImageShape(localStorage.getItem('imageShape'));

//      updateToken();
    }, 1000);
  }

  var logo = (<div>
    <a style={{textDecoration: 'none'}} href={'https://www.PokerHandPost.com'}>
      <img src={prefix+Logo} className="Logo" alt={"Poker Hand Post"}/>
    </a>
    <table style={{width:'100%', padding:'0', margin:'0'}}><tbody>
      <tr>
      <td style={{paddingTop:1}}></td>
      <td style={{padding:0}}>
        <a style={{textDecoration: 'none'}} target={'_blank'} rel={'noopener noreferrer'} href={'https://www.Twitter.com/PokerHandPost'}>
          <img alt={'Poker Hand Post on Twitter'} style={{height:'50px'}} src={TwitterLogo}/>
        </a>
      </td>
      <td style={{padding:0}}>
        <a style={{textDecoration: 'none'}} target={'_blank'} rel={'noopener noreferrer'} href={'https://www.Instagram.com/PokerHandPost'}>
          <img alt={'Poker Hand Post on Instagram'}  style={{height:'50px'}} src={InstagramLogo}/>
        </a>
      </td>
      <td style={{padding:0}}>
        <a style={{textDecoration: 'none'}} target={'_blank'} rel={'noopener noreferrer'} href={'https://www.Facebook.com/PokerHandPost'}>
          <img alt={'Poker Hand Post on Facebook'} style={{height:'50px'}} src={FacebookLogo}/>
        </a>
      </td>
      <td style={{padding:0}}></td>
    </tr>
    <tr>
      <td colSpan={'5'} style={{padding:0}}>
      POKER HAND IMAGE CREATOR
      </td>
    </tr>
    </tbody></table>
  </div>);

  var cardChooserElement =
    cardChooserVisible ? (
      <CardChooser>{cardChooserKey}</CardChooser>
    ) : '';

  var instructionsElement =
    cardChooserVisible ? '' :
      (<div ref={editSectionRef}>
        <div className="BoxTitle">
        INSTRUCTIONS
        </div>
        <div className='InfoBox Instructions'>
        <table><tbody>
        <tr><td style={{textAlign:'left'}}>
        Enter the details of your poker hand, to create an image
        to share on Instagram, Facebook, or Twitter. As you edit, 
        the image will be updated at the top of the page.
        </td>
        </tr></tbody></table>
       </div>
     </div>);

  var eventElement =
    cardChooserVisible ? '' :
      (<div className="BoxTitle">GAME INFO
         <Event onTextChange={onTextChange} eventInputRef={eventInputRef} locationInputRef={locationInputRef}/>
       </div>);

  var thirdPlayer =
    (!('false' === player3Enabled || !player3Enabled)) ? (
      <div>
      <div className="BoxTitle">
        <table style={{width:'100%'}}>
          <tbody>
            <tr>
              <td style={{width:'15%'}}></td>
              <td style={{width:'70%'}}>PLAYER #3</td>
              <td style={{width:'15%'}}><TrashButton onClick={onDisablePlayer3}/></td></tr>
          </tbody>
        </table>
      </div>
      <PlayerForm
        onClickCardChooser={onClickCardChooser}
        onTextChange={onTextChange}
        playerKey='player3'
        defaultPlayerName='Villain #2'
        onUpdateCards={updateCards}
        displayNameInputRef={player3DisplayNameInputRef}
        displayChipsInputRef={player3DisplayChipsInputRef}
        />
      </div>
    ) : (<AddPlayer onTextChange={onTextChange} updateCards={updateCards}/>);
  var playerForms =
    cardChooserVisible ? '' :
      (<div>
        <div className="BoxTitle">
        PLAYER #1
        </div>
        <PlayerForm
          onClickCardChooser={onClickCardChooser}
          onTextChange={onTextChange}
          playerKey='player1'
          defaultPlayerName='Hero'
          onUpdateCards={updateCards}
          displayNameInputRef={player1DisplayNameInputRef}
          displayChipsInputRef={player1DisplayChipsInputRef}
          />
        <div  className="BoxTitle">
        PLAYER #2
        </div>
        <PlayerForm
          onClickCardChooser={onClickCardChooser}
          onTextChange={onTextChange}
          playerKey='player2'
          defaultPlayerName='Villain'
          onUpdateCards={updateCards}
          displayNameInputRef={player2DisplayNameInputRef}
          displayChipsInputRef={player2DisplayChipsInputRef}
          />
        {thirdPlayer}
       </div>);

  var board =
     cardChooserVisible ? '' :
       (<div>
          <div className="BoxTitle">
            HAND DETAILS
          </div>
          <Board
          onClickCardChooser={onClickCardChooser}
          onUpdateCards={updateCards}/>
        </div>);

  var commands =
     cardChooserVisible ? '' :
       (<Commands downloadLink={downloadLink} includeReset={true} handId={handId} imageData={imageData} focusEdit={focusEdit}/>);

  var imageStyleChooserElement =
         cardChooserVisible ? '' :
           (<div>
             <div className="BoxTitle">
             IMAGE STYLE
             </div>
             <div className="InfoBox">
               <ImageStyleChooser onChange={onTextChange}/>
             </div>
          </div>);

  const [boardCard1, setBoardCard1] =
     useState(localStorage.getItem('board:card1'));
  const [boardCard2, setBoardCard2] =
     useState(localStorage.getItem('board:card2'));
  const [boardCard3, setBoardCard3] =
     useState(localStorage.getItem('board:card3'));
  const [boardCard4, setBoardCard4] =
     useState(localStorage.getItem('board:card4'));
  const [boardCard5, setBoardCard5] =
     useState(localStorage.getItem('board:card5'));

  const [player1Card1, setPlayer1Card1] =
     useState(localStorage.getItem('player1:playerCard1'));
  const [player1Card2, setPlayer1Card2] =
     useState(localStorage.getItem('player1:playerCard2'));

  const [player2Card1, setPlayer2Card1] =
     useState(localStorage.getItem('player2:playerCard1'));
  const [player2Card2, setPlayer2Card2] =
     useState(localStorage.getItem('player2:playerCard2'));

  const [player3Card1, setPlayer3Card1] =
     useState(localStorage.getItem('player3:playerCard1'));
  const [player3Card2, setPlayer3Card2] =
     useState(localStorage.getItem('player3:playerCard2'));

  var details =
       cardChooserVisible ? '' :
         (<Details
           onTextChange={onTextChange}
           boardCard1={boardCard1}
           boardCard2={boardCard2}
           boardCard3={boardCard3}
           boardCard4={boardCard4}
           boardCard5={boardCard5}

           player1Name={player1Name ? player1Name : 'Hero'}
           player1Position={player1Position}
           player1Card1={player1Card1}
           player1Card2={player1Card2}

           player2Name={player2Name ? player2Name : 'Villain'}
           player2Position={player2Position}
           player2Card1={player2Card1}
           player2Card2={player2Card2}

           player3Enabled={player3Enabled}
           player3Name={player3Name ? player3Name : 'Villain #2'}
           player3Position={player3Position}
           player3Card1={player3Card1}
           player3Card2={player3Card2}

           blindsInputRef={blindsInputRef}
           detailsInputRef={detailsInputRef}
           />);

  const shareImageOnClick = (event) => {
    console.log('shareImageOnClick');

    const bounds = event.target.getBoundingClientRect();
    console.log('Bounding Rect: ' + JSON.stringify(bounds))

    const clientWidth = event.target.clientWidth;
    const clientHeight = event.target.clientHeight;

    console.log('Client Width/Height ' + clientWidth + ' x ' + clientHeight)
    const naturalWidth = event.target.naturalWidth;
    const naturalHeight = event.target.naturalHeight;

    console.log('Natural Width/Height ' + naturalWidth + ' x ' + naturalHeight)

    const clientX = event.clientX;
    const clientY = event.clientY;

    console.log('Client X/Y ' + clientX + ' x ' + clientY)

    const eventX = clientX - bounds.x;
    const eventY = clientY - bounds.y;

    const adjustmentX = clientWidth / naturalWidth;
    const adjustmentY = clientHeight / naturalHeight;

    console.log('shareImageOnClick: event ' + eventX + ' x ' + eventY)

    clickMap.forEach((entry) => {
//      console.log('shareImageOnClick: entry ' + JSON.stringify(entry))

      const entryX = entry.x * adjustmentX;
      const entryY = entry.y * adjustmentY;
      const entryHeight = entry.height * adjustmentX;
      const entryWidth = entry.width * adjustmentY;

      if (eventX > entryX &&
        eventX < (entryX + entryWidth) &&
        eventY > entryY &&
        eventY < (entryY + entryHeight)) {
          console.log('shareImageOnClick: event MATCH ' + JSON.stringify(entry))

          if (entry.id === 'event') {
            eventInputRef.current.scrollIntoView({behavior:"smooth"});
            eventInputRef.current.focus();
            eventInputRef.current.select();
          } else if (entry.id === 'location') {
            locationInputRef.current.scrollIntoView({behavior:"smooth"});
            locationInputRef.current.focus();
            locationInputRef.current.select();
          } else if (entry.id === 'cardImage1') {
            onClickCardChooser('board:card1')
          } else if (entry.id === 'cardImage2') {
            onClickCardChooser('board:card2')
          } else if (entry.id === 'cardImage3') {
            onClickCardChooser('board:card3')
          } else if (entry.id === 'cardImage4') {
            onClickCardChooser('board:card4')
          } else if (entry.id === 'cardImage5') {
            onClickCardChooser('board:card5')
          } else if (entry.id === 'player1Card1') {
            onClickCardChooser('player1:playerCard1')
          } else if (entry.id === 'player1Card2') {
            onClickCardChooser('player1:playerCard2')
          } else if (entry.id === 'player2Card1') {
            onClickCardChooser('player2:playerCard1')
          } else if (entry.id === 'player2Card2') {
            onClickCardChooser('player2:playerCard2')
          } else if (entry.id === 'player3Card1') {
            onClickCardChooser('player3:playerCard1')
          } else if (entry.id === 'player3Card2') {
            onClickCardChooser('player3:playerCard2')
          } else if (entry.id === 'player1DisplayName') {
            player1DisplayNameInputRef.current.scrollIntoView({behavior:"smooth"})
            player1DisplayNameInputRef.current.focus()
            player1DisplayNameInputRef.current.select()            
          } else if (entry.id === 'player1DisplayChips') {
            player1DisplayChipsInputRef.current.scrollIntoView({behavior:"smooth"})
            player1DisplayChipsInputRef.current.focus()
            player1DisplayChipsInputRef.current.select()
          } else if (entry.id === 'player2DisplayName') {
            player2DisplayNameInputRef.current.scrollIntoView({behavior:"smooth"})
            player2DisplayNameInputRef.current.focus()
            player2DisplayNameInputRef.current.select()
          } else if (entry.id === 'player2DisplayChips') {
            player2DisplayChipsInputRef.current.scrollIntoView({behavior:"smooth"})
            player2DisplayChipsInputRef.current.focus()
            player2DisplayChipsInputRef.current.select()
          } else if (entry.id === 'player3DisplayName') {
            player3DisplayNameInputRef.current.scrollIntoView({behavior:"smooth"})
            player3DisplayNameInputRef.current.focus()
            player3DisplayNameInputRef.current.select()
          } else if (entry.id === 'player3DisplayChips') {
            player3DisplayChipsInputRef.current.scrollIntoView({behavior:"smooth"})
            player3DisplayChipsInputRef.current.focus()
            player3DisplayChipsInputRef.current.select()
          } else if (entry.id === 'blinds') {
            blindsInputRef.current.scrollIntoView({behavior:"smooth"})
            blindsInputRef.current.focus()
            blindsInputRef.current.select()
          } else if (entry.id === 'details') {
            detailsInputRef.current.scrollIntoView({behavior:"smooth"})
            detailsInputRef.current.focus()
            detailsInputRef.current.select()
          }
          // SCROLL TO LABEL REFERENCE
      }
    });
  };

  const shareImageElement = (
     <ShareImage

      addClickMap={addClickMap}
      resetClickMap={resetClickMap}

      boardCard1={boardCard1}
      boardCard2={boardCard2}
      boardCard3={boardCard3}
      boardCard4={boardCard4}
      boardCard5={boardCard5}

      player1Name={player1Name ? player1Name : 'Hero'}
      player1Chips={player1Chips ? player1Chips : ''}
      player1Position={player1Position}
      player1Card1={player1Card1}
      player1Card2={player1Card2}

      player2Name={player2Name ? player2Name : 'Villain'}
      player2Chips={player2Chips ? player2Chips : ''}
      player2Position={player2Position}
      player2Card1={player2Card1}
      player2Card2={player2Card2}

      player3Enabled={player3Enabled}
      player3Name={player3Name ? player3Name : 'Villain #2'}
      player3Chips={player3Chips ? player3Chips : ''}
      player3Position={player3Position}
      player3Card1={player3Card1}
      player3Card2={player3Card2}

      details={detailsText}
      event={eventText}
      location={locationText}
      blinds={blinds}

      odds={odds}
      imageStyle={imageStyle}
      imageShape={imageShape}

      downloadLinkRef={downloadLinkRef}
      share={false}
      updateImageData={updateImageData}
      shareImageOnClick={shareImageOnClick}
      />);

  var shareImage =
     cardChooserVisible ||
     !oddsReady
     ? '' :
       (<div>
         {shareImageElement}
      </div>
       );
  const secondaryImage = cardChooserVisible ? '' : (
    <div style={{margin: '10px'}}>
      <img src={imageData} style={{width:"98%"}} onClick={shareImageOnClick}/>
    </div>
  );

  const year = new Date().getYear() + 1900;
  const copyright = (
    <div className='copyright'>
      <a target={"_blank"} style={{color:'#FFFFFF', textDecoration:'none'}} rel={'noopener noreferrer'} href={"https://www.Facebook.com/PokerHandPost"}>Contact us</a>&nbsp;-&nbsp;
      <a target={"_blank"} style={{color:'#FFFFFF', textDecoration:'none'}} rel={'noopener noreferrer'} href={"https://www.PokerHandPost.com/blog"}>Blog</a>&nbsp;-&nbsp;
      <a target={"_blank"} style={{color:'#FFFFFF', textDecoration:'none'}} rel={'noopener noreferrer'} href={"https://www.PokerHandPost.com/license.txt"}>License</a>&nbsp;-&nbsp;
      <a target={"_blank"} style={{color:'#FFFFFF', textDecoration:'none'}} rel={'noopener noreferrer'} href={"https://www.PokerHandPost.com/privacy.txt"}>Privacy Policy</a>
      <br/><br/>
      (c) {year} Andrew Paris Software, All Rights Reserved.
    </div>
  );
/*
  if (token) {
    return (
      <div className="App">
        {logo}
        {shareImageElement}
        {commands}
        {copyright}
      </div>
    );
  }
*/
  return (
    <div className="App">
      {logo}
      {shareImage}
      {commands}
      {instructionsElement}
      {cardChooserElement}
      {eventElement}
      {board}
      {playerForms}
      {details}
      {imageStyleChooserElement}
      {secondaryImage}
      {copyright}
    </div>
  );
}

export default App;
