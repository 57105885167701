import React from 'react';
import useStateWithLocalStorage from './useStateWithLocalStorage';
import Trash from './img/trash.png';

import './Event.css'

function Event(props) {
  const [event, setEvent] = useStateWithLocalStorage(
    'event'
  );
  const [location, setLocation] = useStateWithLocalStorage(
    'location'
  );
  const onChangeEvent = (evt) => {
    setEvent(evt.target.value);
    props.onTextChange();
  };
  const clearEvent = () => {
    setEvent('');
    props.onTextChange();
  };
  const onChangeLocation = (evt) => {
    setLocation(evt.target.value);
    props.onTextChange();
  };
  const clearLocation = () => {
    setLocation('');
    props.onTextChange();
  }

  var eventValue = event ? event : '';
  var locationValue = location ? location : '';

  var textInputFields =
    (
      <table><tbody>
        <tr>
          <td style={{textAlign:'right'}}>EVENT / GAME<br/>
          <input tabIndex='0' ref={props.eventInputRef} style={{textAlign:'right'}} className='EventInputField' value={eventValue} type="text" onChange={onChangeEvent} /></td>
          <td style={{verticalAlign:'bottom'}}><button className='TrashButton' onClick={clearEvent}>
            <img className='TrashImage' src={Trash} alt="CLEAR"/>
          </button></td>
        </tr>
        <tr>
          <td style={{textAlign:'right'}}>LOCATION<br/>
          <input tabIndex='1' ref={props.locationInputRef} style={{textAlign:'right'}} className='EventInputField' value={locationValue} type="text" onChange={onChangeLocation} /></td>
          <td style={{verticalAlign:'bottom'}}><button className='TrashButton' onClick={clearLocation}>
            <img className='TrashImage' src={Trash} alt="CLEAR"/>
          </button></td>
        </tr>
      </tbody></table>
    );

  return (
    <div className='Event'>
      {textInputFields}
    </div>
  );
}

export default Event;
