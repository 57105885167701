import React from 'react';
import useStateWithLocalStorage from './useStateWithLocalStorage';
import Card from './Card';
import TrashButton from './TrashButton';

import './Board.css'

function Board(props) {

  const playerKeyValue = 'board';
  const [card1, setCard1] = useStateWithLocalStorage(
    playerKeyValue + ':card1'
  );
  const [card2, setCard2] = useStateWithLocalStorage(
    playerKeyValue + ':card2'
  );
  const [card3, setCard3] = useStateWithLocalStorage(
    playerKeyValue + ':card3'
  );
  const [card4, setCard4] = useStateWithLocalStorage(
    playerKeyValue + ':card4'
  );
  const [card5, setCard5] = useStateWithLocalStorage(
    playerKeyValue + ':card5'
  );

  const clearBoard = () => {
    setCard1('CB');
    setCard2('CB');
    setCard3('CB');
    setCard4('CB');
    setCard5('CB');
    props.onUpdateCards();
  }

  var card1Value = card1 ? card1 : 'CB';
  var card2Value = card2 ? card2 : 'CB';
  var card3Value = card3 ? card3 : 'CB';
  var card4Value = card4 ? card4 : 'CB';
  var card5Value = card5 ? card5 : 'CB';

  var fields =
  (
    <table className="BoardTable">
      <tbody>
        <tr>
          <td colSpan="5">
            BOARD
          </td>
        </tr>
        <tr>
          <td>
            <button
              className='NoBorder'
              onClick={(event)=>{event.preventDefault();props.onClickCardChooser(playerKeyValue + ':card1');}}>
              <Card>
                {card1Value}
              </Card>
            </button>
          </td>
          <td>
            <button
              className='NoBorder'
              onClick={(event)=>{event.preventDefault();props.onClickCardChooser(playerKeyValue + ':card2');}}>
              <Card>
                {card2Value}
              </Card>
            </button>
          </td>
          <td>
            <button
              className='NoBorder'
              onClick={(event)=>{event.preventDefault();props.onClickCardChooser(playerKeyValue + ':card3');}}>
              <Card>
                {card3Value}
              </Card>
            </button>
          </td>
          <td>
            <button
              className='NoBorder'
              onClick={(event)=>{event.preventDefault();props.onClickCardChooser(playerKeyValue + ':card4');}}>
              <Card>
                {card4Value}
              </Card>
            </button>
          </td>
          <td>
            <button
              className='NoBorder'
              onClick={(event)=>{event.preventDefault();props.onClickCardChooser(playerKeyValue + ':card5');}}>
              <Card>
                {card5Value}
              </Card>
            </button>
          </td>
        </tr>
        <tr>
          <td colSpan='1'>
            <TrashButton onClick={clearBoard}/>
          </td>
          <td colSpan='1' className='Text'>FLOP</td>
          <td colSpan='1' className='Text'></td>
          <td colSpan='1' className='Text'>TURN</td>
          <td colSpan='1' className='Text'>RIVER</td>

        </tr>

      </tbody>
    </table>
  );

  return (
    <div className='Board'>
      {fields}
    </div>
  );
}

export default Board;
