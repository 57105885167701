import S2 from './img/new-deck/2S.svg';
import H2 from './img/new-deck/2H.svg';
import D2 from './img/new-deck/2D.svg';
import C2 from './img/new-deck/2C.svg';

import S3 from './img/new-deck/3S.svg';
import H3 from './img/new-deck/3H.svg';
import D3 from './img/new-deck/3D.svg';
import C3 from './img/new-deck/3C.svg';

import S4 from './img/new-deck/4S.svg';
import H4 from './img/new-deck/4H.svg';
import D4 from './img/new-deck/4D.svg';
import C4 from './img/new-deck/4C.svg';

import S5 from './img/new-deck/5S.svg';
import H5 from './img/new-deck/5H.svg';
import D5 from './img/new-deck/5D.svg';
import C5 from './img/new-deck/5C.svg';

import S6 from './img/new-deck/6S.svg';
import H6 from './img/new-deck/6H.svg';
import D6 from './img/new-deck/6D.svg';
import C6 from './img/new-deck/6C.svg';

import S7 from './img/new-deck/7S.svg';
import H7 from './img/new-deck/7H.svg';
import D7 from './img/new-deck/7D.svg';
import C7 from './img/new-deck/7C.svg';

import S8 from './img/new-deck/8S.svg';
import H8 from './img/new-deck/8H.svg';
import D8 from './img/new-deck/8D.svg';
import C8 from './img/new-deck/8C.svg';

import S9 from './img/new-deck/9S.svg';
import H9 from './img/new-deck/9H.svg';
import D9 from './img/new-deck/9D.svg';
import C9 from './img/new-deck/9C.svg';

import ST from './img/new-deck/TS.svg';
import HT from './img/new-deck/TH.svg';
import DT from './img/new-deck/TD.svg';
import CT from './img/new-deck/TC.svg';

import SJ from './img/new-deck/JS.svg';
import HJ from './img/new-deck/JH.svg';
import DJ from './img/new-deck/JD.svg';
import CJ from './img/new-deck/JC.svg';

import SQ from './img/new-deck/QS.svg';
import HQ from './img/new-deck/QH.svg';
import DQ from './img/new-deck/QD.svg';
import CQ from './img/new-deck/QC.svg';

import SK from './img/new-deck/KS.svg';
import HK from './img/new-deck/KH.svg';
import DK from './img/new-deck/KD.svg';
import CK from './img/new-deck/KC.svg';

import SA from './img/new-deck/AS.svg';
import HA from './img/new-deck/AH.svg';
import DA from './img/new-deck/AD.svg';
import CA from './img/new-deck/AC.svg';

import CB from './img/new-deck/CB_blue.svg';

const getCardImage = (card) => {
  const prefix = 'https://assets.pokerhandpost.com'
//  const prefix = 'http://localhost:3000'

  switch (card) {
    case '2c': {return prefix + C2;}
    case '3c': {return prefix + C3;}
    case '4c': {return prefix + C4;}
    case '5c': {return prefix + C5;}
    case '6c': {return prefix + C6;}
    case '7c': {return prefix + C7;}
    case '8c': {return prefix + C8;}
    case '9c': {return prefix + C9;}
    case 'Tc': {return prefix + CT;}
    case 'Jc': {return prefix + CJ;}
    case 'Qc': {return prefix + CQ;}
    case 'Kc': {return prefix + CK;}
    case 'Ac': {return prefix + CA;}

    case '2s': {return prefix + S2;}
    case '3s': {return prefix + S3;}
    case '4s': {return prefix + S4;}
    case '5s': {return prefix + S5;}
    case '6s': {return prefix + S6;}
    case '7s': {return prefix + S7;}
    case '8s': {return prefix + S8;}
    case '9s': {return prefix + S9;}
    case 'Ts': {return prefix + ST;}
    case 'Js': {return prefix + SJ;}
    case 'Qs': {return prefix + SQ;}
    case 'Ks': {return prefix + SK;}
    case 'As': {return prefix + SA;}

    case '2d': {return prefix + D2;}
    case '3d': {return prefix + D3;}
    case '4d': {return prefix + D4;}
    case '5d': {return prefix + D5;}
    case '6d': {return prefix + D6;}
    case '7d': {return prefix + D7;}
    case '8d': {return prefix + D8;}
    case '9d': {return prefix + D9;}
    case 'Td': {return prefix + DT;}
    case 'Jd': {return prefix + DJ;}
    case 'Qd': {return prefix + DQ;}
    case 'Kd': {return prefix + DK;}
    case 'Ad': {return prefix + DA;}

    case '2h': {return prefix + H2;}
    case '3h': {return prefix + H3;}
    case '4h': {return prefix + H4;}
    case '5h': {return prefix + H5;}
    case '6h': {return prefix + H6;}
    case '7h': {return prefix + H7;}
    case '8h': {return prefix + H8;}
    case '9h': {return prefix + H9;}
    case 'Th': {return prefix + HT;}
    case 'Jh': {return prefix + HJ;}
    case 'Qh': {return prefix + HQ;}
    case 'Kh': {return prefix + HK;}
    case 'Ah': {return prefix + HA;}

    default: {
      return prefix + CB;
    }
  }
}

export default getCardImage;
