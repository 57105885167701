import React from 'react';

import './PlusButton.css'
import Plus from './img/plus.png';

function PlusButton(props) {
  return <button className='PlusButton' onClick={props.onClick}>
    <img className='TrashImage' src={Plus} alt="ADD"/>
    </button>;
}

export default PlusButton;
