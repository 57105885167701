import React, { useState } from 'react';
import useStateWithLocalStorage from './useStateWithLocalStorage';
import getCardImage from './getCardImage.js';

import SS from './img/card_suits_spade.png';
import HS from './img/card_suits_heart.png';
import DS from './img/card_suits_diamond.png';
import CS from './img/card_suits_club.png';

import './CardChooser.css';

function CardChooser(cardKey) {
  console.log('CardChooser: Key = ' + cardKey.children);

  const cardsInUse = [
    localStorage.getItem('board:card1'),
    localStorage.getItem('board:card2'),
    localStorage.getItem('board:card3'),
    localStorage.getItem('board:card4'),
    localStorage.getItem('board:card5'),
    localStorage.getItem('player1:playerCard1'),
    localStorage.getItem('player1:playerCard2'),
    localStorage.getItem('player2:playerCard1'),
    localStorage.getItem('player2:playerCard2')
  ];

  const [suit, setSuit] = useState('H');
  const [card, setCard] = useStateWithLocalStorage(cardKey.children);

  const player3Enabled = localStorage.getItem('player3Enabled');

  if ('true' === player3Enabled) {
    cardsInUse.push(localStorage.getItem('player3:playerCard1'));
    cardsInUse.push(localStorage.getItem('player3:playerCard2'));
  }

  const chooseCard = (card) => {
    console.log('Card Chosen: ' + card);
    setCard(card);
    setTimeout(()=>{
      var event = new Event('cardChosen');
      document.dispatchEvent(event);
    },1);
  }

  console.log("Card: " + card);

  var clubStyle = { 'display': 'none' };
  var heartStyle = { 'display': 'none' };
  var spadeStyle = { 'display': 'none' };
  var diamondStyle = { 'display': 'none' };

  switch (suit) {
    case 'H': {
      heartStyle = { 'display': 'block' };
      break;
    }
    case 'S': {
      spadeStyle = { 'display': 'block' };
      break;
    }
    case 'D': {
      diamondStyle = { 'display': 'block' };
      break;
    }
    case 'C': {
      clubStyle = { 'display': 'block' };
      break;
    }
    default: {}
  }

  const getCardElement = (name) => {
    var onClick = () => {
      console.log('onClick: ' + name);
      chooseCard(name);
    };
    var className = "ChooserCardImage";

    if (cardsInUse.includes(name) && 'CB' !== name) {
      className = "DisabledCard ChooserCardImage";
    }

    return (<img src={getCardImage(name)} className={className} alt={name} onClick={onClick}/>);
  };

  const clubPicker = (
    <table style={clubStyle}><tbody><tr>
    <td>
      {getCardElement('CB')}
    </td><td>
      {getCardElement('2c')}
    </td><td>
      {getCardElement('3c')}
    </td><td>
      {getCardElement('4c')}
    </td><td>
      {getCardElement('5c')}
    </td></tr>
    <tr><td>
      {getCardElement('6c')}
    </td><td>
      {getCardElement('7c')}
    </td><td>
      {getCardElement('8c')}
    </td><td>
      {getCardElement('9c')}
    </td><td>
      {getCardElement('Tc')}
    </td></tr>
    <tr><td>
      {getCardElement('Jc')}
    </td><td>
      {getCardElement('Qc')}
    </td><td>
      {getCardElement('Kc')}
    </td><td>
      {getCardElement('Ac')}
    </td><td>
      {getCardElement('CB')}
    </td>
    </tr></tbody></table>
  );

  const heartPicker = (
    <table style={heartStyle}><tbody><tr>
    <td>
      {getCardElement('CB')}
    </td><td>
      {getCardElement('2h')}
    </td><td>
      {getCardElement('3h')}
    </td><td>
      {getCardElement('4h')}
    </td><td>
      {getCardElement('5h')}
    </td></tr>
    <tr><td>
      {getCardElement('6h')}
    </td><td>
      {getCardElement('7h')}
    </td><td>
      {getCardElement('8h')}
    </td><td>
      {getCardElement('9h')}
    </td><td>
      {getCardElement('Th')}
    </td></tr>
    <tr><td>
      {getCardElement('Jh')}
    </td><td>
      {getCardElement('Qh')}
    </td><td>
      {getCardElement('Kh')}
    </td><td>
      {getCardElement('Ah')}
    </td><td>
      {getCardElement('CB')}
    </td>
    </tr></tbody></table>
  );

  const diamondPicker = (
    <table style={diamondStyle}><tbody><tr>
    <td>
      {getCardElement('CB')}
    </td><td>
      {getCardElement('2d')}
    </td><td>
      {getCardElement('3d')}
    </td><td>
      {getCardElement('4d')}
    </td><td>
      {getCardElement('5d')}
    </td></tr>
    <tr><td>
      {getCardElement('6d')}
    </td><td>
      {getCardElement('7d')}
    </td><td>
      {getCardElement('8d')}
    </td><td>
      {getCardElement('9d')}
    </td><td>
      {getCardElement('Td')}
    </td></tr>
    <tr><td>
      {getCardElement('Jd')}
    </td><td>
      {getCardElement('Qd')}
    </td><td>
      {getCardElement('Kd')}
    </td><td>
      {getCardElement('Ad')}
    </td><td>
      {getCardElement('CB')}
    </td>
    </tr></tbody></table>
  );

  const spadePicker = (
    <table style={spadeStyle}><tbody><tr>
    <td>
      {getCardElement('CB')}
    </td><td>
      {getCardElement('2s')}
    </td><td>
      {getCardElement('3s')}
    </td><td>
      {getCardElement('4s')}
    </td><td>
      {getCardElement('5s')}
    </td></tr>
    <tr><td>
      {getCardElement('6s')}
    </td><td>
      {getCardElement('7s')}
    </td><td>
      {getCardElement('8s')}
    </td><td>
      {getCardElement('9s')}
    </td><td>
      {getCardElement('Ts')}
    </td></tr>
    <tr><td>
      {getCardElement('Js')}
    </td><td>
      {getCardElement('Qs')}
    </td><td>
      {getCardElement('Ks')}
    </td><td>
      {getCardElement('As')}
    </td><td>
      {getCardElement('CB')}
    </td>
    </tr></tbody></table>
  );

  const heartClick = () => {
    setSuit('H');
  }

  const spadeClick = () => {
    setSuit('S');
  }

  const clubClick = () => {
    setSuit('C');
  }

  const diamondClick = () => {
    setSuit('D');
  }

  const prefix = "https://assets.pokerhandpost.com";

  return (
    <div className="CardChooser">
      <br/>

    <div>CHOOSE CARD</div>
    {spadePicker}
    {heartPicker}
    {clubPicker}
    {diamondPicker}
    <br/>

      <div>CHANGE SUIT</div>
      <table style={{backgroundColor:'#FFFFFF'}}>
        <tbody>
          <tr>
      <td>
      <button onClick={spadeClick} className='SuitButton'>
        <img src={prefix+SS} className="SuitImage" alt="SS"/>
      </button>
      </td><td>
      <button onClick={heartClick} className='SuitButton'>
      <img src={prefix+HS} className="SuitImage" alt="HS"/>
      </button>
      </td><td>
      <button onClick={diamondClick} className='SuitButton'>
      <img src={prefix+DS} className="SuitImage" alt="DS"/>
      </button>
      </td><td>
      <button onClick={clubClick} className='SuitButton'>
      <img src={prefix+CS} className="SuitImage" alt="CS"/>
      </button>
      </td>
      </tr></tbody></table>
      <br/>
    </div>
  );
}

export default CardChooser;
