import React from 'react';

import './ActionDropDown.css'
import PlusButton from './PlusButton';
import { useState } from 'react';

function ActionDropDown(props) {
  // TODO: Cards populate drop-down
  // TODO: Add button adds text to details, clears fields

  const [selected, setSelected] = useState(
    '1.checks'
  )

  const [amount, setAmount] = useState(
    ''
  )

  const handleKeyPress = (event) => {
//    if (event.key === 13) {
//      event.target.blur()
//    }
  };

  const onChangeAmount = (event) => {
    console.log('onChangeAmount: ' + event.target.value)
    setAmount(event.target.value)
  }

  const onChangeSelect = (event) => {
    console.log('onChangeSelect: ' + event.target.value)
    setSelected(event.target.value)
    setAmount('')
  }

  const addToDetails = () => {
    console.log('addToDetails')

    console.log('selected: ' + selected)
    console.log('amount: ' + amount)

    var text = ''

    if (selected.startsWith('1.')) {
      text = props.player1Name
    } else if (selected.startsWith('2.')) {
      text = props.player2Name
    } else if (selected.startsWith('3.')) {
      text = props.player3Name
    }

    if (selected.endsWith('.bets')) {
      text += ' bets ' + amount + '.'
    } else if (selected.endsWith('.checks')) {
      text += ' checks.'
    } else if (selected.endsWith('.calls')) {
      text += ' calls.'
    } else if (selected.endsWith('.folds')) {
      text += ' folds.'
    } else if (selected.endsWith('.bets')) {
      text += ' bets ' + amount + '.'
    } else if (selected.endsWith('.raises')) {
      text += ' raises ' + amount + '.'
    } else if (selected.endsWith('.raisesto')) {
      text += ' raises to ' + amount + '.'
    } else if (selected.endsWith('.raisesai')) {
      text += ' moves all-in.'
    } else if (selected.endsWith('.has')) {
      text += ' has ' + amount + ' remaining.'
    } else if (selected.endsWith('.remaining')) {
      text += amount + ' players are remaining.'
    } else if (selected.endsWith('.flop')) {
      text += '\n\nFlop is ' + props.boardCard1 + ' ' + props.boardCard2 + ' ' + props.boardCard3 + '.'
    } else if (selected.endsWith('.turn')) {
      text += '\n\nTurn is ' + props.boardCard4 + '.'
    } else if (selected.endsWith('.river')) {
      text += '\n\nRiver is ' + props.boardCard5 + '.'
    }

    console.log('Text: ' + text)
    props.appendText(text + ' ')
  }

  var includePlayer3 = false

  if (!('false' === props.player3Enabled || !props.player3Enabled)) {
    includePlayer3 = true
  }

  var optionsList = (
    <select name="actionToAdd" id="actionToAdd" className='ActionDropDownSelect' onChange={onChangeSelect}>
    <option value="1.checks">{props.player1Name} checks</option>
    <option value="1.calls">{props.player1Name} calls</option>
    <option value="1.folds">{props.player1Name} folds</option>
    <option value="1.bets">{props.player1Name} bets</option>
    <option value="1.raises">{props.player1Name} raises</option>
    <option value="1.raisesto">{props.player1Name} raises to</option>
    <option value="1.raisesai">{props.player1Name} goes all-in</option>
    <option value="1.has">{props.player1Name} has</option>
    <option value="2.checks">{props.player2Name} checks</option>
    <option value="2.calls">{props.player2Name} calls</option>
    <option value="2.folds">{props.player2Name} folds</option>
    <option value="2.bets">{props.player2Name} bets</option>
    <option value="2.raises">{props.player2Name} raises</option>
    <option value="2.raisesto">{props.player2Name} raises to</option>
    <option value="2.raisesai">{props.player2Name} moves all-in</option>
    <option value="2.has">{props.player2Name} has</option>
    {includePlayer3 && <option value="3.checks">{props.player3Name} checks</option>}
    {includePlayer3 && <option value="3.bets">{props.player3Name} bets</option>}
    {includePlayer3 && <option value="3.calls">{props.player3Name} calls</option>}
    {includePlayer3 && <option value="3.folds">{props.player3Name} folds</option>}
    {includePlayer3 && <option value="3.raises">{props.player3Name} raises</option>}
    {includePlayer3 && <option value="3.raisesto">{props.player3Name} raises to</option>}
    {includePlayer3 && <option value="3.raisesai">{props.player3Name} raises all-in</option>}
    {includePlayer3 && <option value="3.has">{props.player3Name} has</option>}
    { props.boardCard1 &&
      props.boardCard1.length === 2 &&
      'CB' !== props.boardCard1 &&
      props.boardCard2 &&
      props.boardCard2.length === 2 &&
      'CB' !== props.boardCard2 &&
      props.boardCard3 &&
      props.boardCard3.length === 2 &&
      'CB' !== props.boardCard3 &&
      <option value=".flop">Flop is {props.boardCard1} {props.boardCard2} {props.boardCard3}</option>}
    { props.boardCard4 &&
      props.boardCard4.length === 2 &&
      'CB' !== props.boardCard4 &&
      <option value=".turn">Turn is {props.boardCard4}</option>}
    { props.boardCard5 &&
      props.boardCard5.length === 2 &&
      'CB' !== props.boardCard5 &&
      <option value=".river">River is {props.boardCard5}</option>}
    )
    <option value=".remaining">Players remaining</option>
    </select>
  );

  var amountEnabled = false

  if (selected.endsWith('.bets') || selected.endsWith('.raisesto') || selected.endsWith('.raises') || selected.endsWith('.has') || selected.endsWith('.remaining')) {
    amountEnabled = true
  }

  return (<div>
    {optionsList}
    {amountEnabled &&
    <input style={{textAlign:'center', width: 'calc(90px + 2vmin)'}} className='InputField' type='text' onChange={onChangeAmount} value={amount}  onKeyPress={handleKeyPress}/>}
    <br/>
    <PlusButton onClick={addToDetails}/>
  </div>)
}

export default ActionDropDown;
