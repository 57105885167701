const saveImageData = (handId, imageData) => {
  console.log('save: ' + handId + ' = ' + imageData);

  const request = new XMLHttpRequest();

  request.onreadystatechange = function() {//Call a function when the state changes.
      // TODO: Handle errors
      if (request.readyState === 4 && request.status === 200) {
          console.log('XML HTTP Response: ' + request.responseText);
      }
  };

  request.open('POST', 'https://mw8hdnd4jj.execute-api.us-west-2.amazonaws.com/default/pokerHandPostShare', true);
  request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

  const parameters = {};
  parameters.handId = handId;
  parameters.imageData = imageData;
  const requestContent = JSON.stringify(parameters);
  console.log('XML HTTP Request: ' + requestContent);
  request.send(requestContent);
};

export default {saveImageData};
