import React from 'react';
import LinkIcon from './img/LinkIcon.png';
import Trash from './img/trash.png';
import Pencil from './img/pencil.png';
import WhiteSpade from './img/white_spade.png';
import serverStore from './serverStore.js';

import './Commands.css'

function Commands(props) {
  const clear = () => {
    localStorage.clear();
    window.location.reload();
  };

  const clearSpade = () => {
    localStorage.clear();
    localStorage.setItem('player1:playerName', 'Hero')
    localStorage.setItem('player1:playerChips', '25,000')
    localStorage.setItem('player1:playerPosition', 'D')
    localStorage.setItem('player1:playerCard1', 'Ah');
    localStorage.setItem('player1:playerCard2', 'Kh');

    localStorage.setItem('player2:playerName', 'Villain')
    localStorage.setItem('player2:playerChips', '10,000')
    localStorage.setItem('player2:playerPosition', 'BB')
    localStorage.setItem('player2:playerCard1', 'Ac');
    localStorage.setItem('player2:playerCard2', 'As');

    localStorage.setItem('board:card1', 'Ad');
    localStorage.setItem('board:card2', 'Qh');
    localStorage.setItem('board:card3', 'Jh');
    localStorage.setItem('board:card4', 'Jc');
    localStorage.setItem('board:card5', 'Th');

    localStorage.setItem('event', 'World Series of Poker');
    localStorage.setItem('location', 'Paris Las Vegas');
    localStorage.setItem('details', 'Hero raises to 2 big blinds. Villain goes all in. Hero calls.');
    localStorage.setItem('blinds', '1,000 / 2,000 / 2,000');

    window.location.reload();
  };

  let location = window.location.href;

  if (location.includes('?')) {
    location = location.substring(0, location.indexOf('?'));
  }
  const saveLink = () => {
    console.log('saveLink');

    if (!props.imageData) {
      return;
    }

    console.log('saveLink: handId = ' + props.handId);
    console.log('saveLink: imageData = ' + props.imageData.length);
    serverStore.saveImageData(props.handId, props.imageData);
  };
  const linkRef = ('share.php?handId=' + props.handId).replace('//','/');
  const shareLink = (
      <a target={"_blank"} style={{color:'#FFFFFF', textDecoration:'none'}} rel={'noopener noreferrer'} href={linkRef} onClick={saveLink}>
        <img alt={'Share Link'} style={{height:'35px', marginHeight:'0'}} src={LinkIcon}/>
      </a>
  );

  const editLink = (
    <a style={{color:'#FFFFFF', textDecoration:'none'}} onClick={props.focusEdit}>
      <img alt={'Edit'} style={{height:'35px', marginHeight:'0'}} src={Pencil}/>
    </a>
  );

  const spadeLink = (
    <a style={{color:'#FFFFFF', textDecoration:'none'}} onClick={clearSpade}>
      <img alt={'Edit'} style={{height:'35px', marginHeight:'0'}} src={WhiteSpade}/>
    </a>
  );

  const trashLink = (
    <a style={{color:'#FFFFFF', textDecoration:'none'}} onClick={clear}>
      <img alt={'Reset'} style={{height:'35px', marginHeight:'0'}} src={Trash}/>
    </a>
  );

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>
              <div className='Commands'>
                <table>
                  <tbody>
                    <tr><td>EDIT</td></tr>
                    <tr>
                      <td>
                        {editLink}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>

            {
              props.includeReset ? (
                <td>
                  <div className='Commands'>
                    <table>
                      <tbody>
                        <tr><td colSpan={3}>RESET</td></tr>
                        <tr>
                          <td>
                            {spadeLink}
                          </td>
                          <td>
                            &nbsp;&nbsp;
                          </td>
                          <td>
                            {trashLink}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              ) : ''
            }

            <td>
              <div className='Commands'>
                <table>
                  <tbody>
                    <tr><td colSpan={3}>SHARE</td></tr>
                    <tr>
                      <td>
                        {shareLink}
                      </td>
                      <td>
                        &nbsp;&nbsp;
                      </td>
                      <td>
                        {props.downloadLink}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>

          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Commands;
