import React from 'react';
import useStateWithLocalStorage from './useStateWithLocalStorage';

import './AddPlayer.css'

function AddPlayer(props) {
  const [player3Enabled, setPlayer3Enabled] = useStateWithLocalStorage(
    'player3Enabled'
  );

  const addPlayer = () => {
    setPlayer3Enabled(true);
    props.onTextChange();
    props.updateCards();
  };

  console.log('player3Enabled: ' + player3Enabled);

  return (
    <div className='AddPlayer'>
      <button className='AddPlayerButton NoBorder' onClick={addPlayer}>+ ADD PLAYER</button>
    </div>
  );
}

export default AddPlayer;
