import React from 'react';
import Trash from './img/trash.png';

import './TrashButton.css'

function TrashButton(props) {
  return <button className='TrashButton' onClick={props.onClick}>
       <img className='TrashImage' src={Trash} alt="CLEAR"/>
     </button>;
}

export default TrashButton;
