import React from 'react';
import getCardImage from './getCardImage.js';
import checkImage from './img/check.png';
import icon from './img/loading.svg';

import './ShareImage.css';

import getTextLines from './getTextLines.js';

const prefix = 'https://assets.pokerhandpost.com';

const checkElm = new Image();
checkElm.crossOrigin = "Anonymous";
checkElm.src = checkImage;

var pngTimer = undefined;
var imgTimer = undefined;

const SHAPE_SQUARE = 2;

const THEME_1 = 1;
const THEME_1_BORDER_COLOR = '#FFFFBB';
const THEME_1_FONT_COLOR = '#FFFFBB';
const THEME_1_BACKGROUND_COLOR = '#282c34';

const THEME_2 = 2;
const THEME_2_BORDER_COLOR = '#FFFFFF';
const THEME_2_FONT_COLOR = '#FFFFFF';
const THEME_2_BACKGROUND_COLOR = '#000000';

// const THEME_3 = 3;
const THEME_3_BORDER_COLOR = '#333333';
const THEME_3_FONT_COLOR = '#333333';
const THEME_3_BACKGROUND_COLOR = '#EEEEEE';

const THEME_4 = 4;
const THEME_4_BORDER_COLOR = '#FFFFFF';
const THEME_4_FONT_COLOR = '#FFFFFF';
const THEME_4_BACKGROUND_COLOR = '#7d00a3';

const THEME_5 = 5;
const THEME_5_BORDER_COLOR = '#000000';
const THEME_5_FONT_COLOR = '#000000';
const THEME_5_BACKGROUND_COLOR = '#ffb3d1';

const THEME_6 = 6;
const THEME_6_BORDER_COLOR = '#000000';
const THEME_6_FONT_COLOR = '#000000';
const THEME_6_BACKGROUND_COLOR = '#a0ff99';

const THEME_7 = 7;
const THEME_7_BORDER_COLOR = '#000000';
const THEME_7_FONT_COLOR = '#000000';
const THEME_7_BACKGROUND_COLOR = '#ffe066';

const THEME_8 = 8;
const THEME_8_BORDER_COLOR = '#000000';
const THEME_8_FONT_COLOR = '#000000';
const THEME_8_BACKGROUND_COLOR = '#99ddff';

const THEME_9 = 9;
const THEME_9_BORDER_COLOR = '#000000';
const THEME_9_FONT_COLOR = '#000000';
const THEME_9_BACKGROUND_COLOR = '#ffffcc';

const THEME_10 = 10;
const THEME_10_BORDER_COLOR = '#FFFFFF';
const THEME_10_FONT_COLOR = '#FFFFFF';
const THEME_10_BACKGROUND_COLOR = '#0510cc';

const THEME_11 = 11;
const THEME_11_BORDER_COLOR = '#FFFFFF';
const THEME_11_FONT_COLOR = '#FFFFFF';
const THEME_11_BACKGROUND_COLOR = '#ff5033';

const THEME_12 = 12;
const THEME_12_BORDER_COLOR = '#eeeeee';
const THEME_12_FONT_COLOR = '#eeeeee';
const THEME_12_BACKGROUND_COLOR = '#004400';

function ShareImage(props) {

  const redLines = false
  const detailsText = props.details ? props.details : '';
  const blinds = props.blinds ? props.blinds : '';
  const textLines = getTextLines(detailsText);

  const boardCard1Image = getCardImage(props.boardCard1);
  const boardCard2Image = getCardImage(props.boardCard2);
  const boardCard3Image = getCardImage(props.boardCard3);
  const boardCard4Image = getCardImage(props.boardCard4);
  const boardCard5Image = getCardImage(props.boardCard5);

  const player1Card1Image = getCardImage(props.player1Card1);
  const player1Card2Image = getCardImage(props.player1Card2);

  const player2Card1Image = getCardImage(props.player2Card1);
  const player2Card2Image = getCardImage(props.player2Card2);

  const player3Card1Image = getCardImage(props.player3Card1);
  const player3Card2Image = getCardImage(props.player3Card2);
  const player3Enabled = props.player3Enabled;

  const imageStyleValue = props.imageStyle;
  const imageShapeValue = props.imageShape;

  const getBorderColor = (imageStyleValue) => {
    switch (parseInt(imageStyleValue)) {
      case THEME_12: {
        return THEME_12_BORDER_COLOR;
      }
      case THEME_11: {
        return THEME_11_BORDER_COLOR;
      }
      case THEME_10: {
        return THEME_10_BORDER_COLOR;
      }
      case THEME_9: {
        return THEME_9_BORDER_COLOR;
      }
      case THEME_8: {
        return THEME_8_BORDER_COLOR;
      }
      case THEME_7: {
        return THEME_7_BORDER_COLOR;
      }
      case THEME_6: {
        return THEME_6_BORDER_COLOR;
      }
      case THEME_5: {
        return THEME_5_BORDER_COLOR;
      }
      case THEME_4: {
        return THEME_4_BORDER_COLOR;
      }
      case THEME_1: {
        return THEME_1_BORDER_COLOR;
      }
      case THEME_2: {
        return THEME_2_BORDER_COLOR;
      }
      default: {
        return THEME_3_BORDER_COLOR;
      }
    }
  };

  const getBackgroundColor = (imageStyleValue) => {
    switch (parseInt(imageStyleValue)) {
      case THEME_12: {
        return THEME_12_BACKGROUND_COLOR;
      }
      case THEME_11: {
        return THEME_11_BACKGROUND_COLOR;
      }
      case THEME_10: {
        return THEME_10_BACKGROUND_COLOR;
      }
      case THEME_9: {
        return THEME_9_BACKGROUND_COLOR;
      }
      case THEME_8: {
        return THEME_8_BACKGROUND_COLOR;
      }
      case THEME_7: {
        return THEME_7_BACKGROUND_COLOR;
      }
      case THEME_6: {
        return THEME_6_BACKGROUND_COLOR;
      }
      case THEME_5: {
        return THEME_5_BACKGROUND_COLOR;
      }
      case THEME_4: {
        return THEME_4_BACKGROUND_COLOR;
      }
      case THEME_1: {
        return THEME_1_BACKGROUND_COLOR;
      }
      case THEME_2: {
        return THEME_2_BACKGROUND_COLOR;
      }
      default: {
        return THEME_3_BACKGROUND_COLOR;
      }
    }
  };

  const getFontColor = (imageStyleValue) => {
    switch (parseInt(imageStyleValue)) {
      case THEME_12: {
        return THEME_12_FONT_COLOR;
      }
      case THEME_11: {
        return THEME_11_FONT_COLOR;
      }
      case THEME_10: {
        return THEME_10_FONT_COLOR;
      }
      case THEME_9: {
        return THEME_9_FONT_COLOR;
      }
      case THEME_8: {
        return THEME_8_FONT_COLOR;
      }
      case THEME_7: {
        return THEME_7_FONT_COLOR;
      }
      case THEME_6: {
        return THEME_6_FONT_COLOR;
      }
      case THEME_5: {
        return THEME_5_FONT_COLOR;
      }
      case THEME_4: {
        return THEME_4_FONT_COLOR;
      }
      case THEME_1: {
        return THEME_1_FONT_COLOR;
      }
      case THEME_2: {
        return THEME_2_FONT_COLOR;
      }
      default: {
        return THEME_3_FONT_COLOR;
      }
    }
  };

  var borderColor = getBorderColor(imageStyleValue);
  var fontColor = getFontColor(imageStyleValue);
  var backgroundColor = getBackgroundColor(imageStyleValue);

  const width = 628;

  const canvasRef = React.useRef(null);
  const imageRef = React.useRef(null);

  var player1Wins = 0;
  var player2Wins = 0;
  var player3Wins = 0;
  var player1PreFlopOdds = '';
  var player2PreFlopOdds = '';
  var player3PreFlopOdds = '';
  var player1FlopOdds = '';
  var player2FlopOdds = '';
  var player3FlopOdds = '';
  var player1TurnOdds = '';
  var player2TurnOdds = '';
  var player3TurnOdds = '';
  var oddsLines = 0;

  props.resetClickMap();

  if (props.odds) {
    if (props.odds.preFlop && props.odds.preFlop.length > 0) {
      // console.log("PREFLOP OBJ: " + JSON.stringify(props.odds.preFlop));
      player1Wins = props.odds.preFlop[0].wins;
      player2Wins = props.odds.preFlop[1].wins;
      player1PreFlopOdds = (100.0 * player1Wins / props.odds.preFlop[0].count).toFixed(1);
      player2PreFlopOdds = (100.0 * player2Wins / props.odds.preFlop[1].count).toFixed(1);

      console.log("Player 1 Pre-Flop Odds: " + player1PreFlopOdds);
      console.log("Player 2 Pre-Flop Odds: " + player2PreFlopOdds);

      if (props.odds.preFlop.length > 2) {
        player3Wins = props.odds.preFlop[2].wins;
        player3PreFlopOdds = (100.0 * player3Wins / props.odds.preFlop[2].count).toFixed(1);
        console.log("Player 3 Pre-Flop Odds: " + player3PreFlopOdds);
      }

      ++oddsLines;
    }

    if (props.odds.flop && props.odds.flop.length > 0) {
      // console.log("FLOP OBJ: " + JSON.stringify(props.odds.flop));
      player1Wins = props.odds.flop[0].wins;
      player2Wins = props.odds.flop[1].wins;

      player1FlopOdds = (100.0 * player1Wins / props.odds.flop[0].count).toFixed(1);
      player2FlopOdds = (100.0 * player2Wins / props.odds.flop[1].count).toFixed(1);
      console.log("Player 1 Flop Odds: " + player1FlopOdds);
      console.log("Player 2 Flop Odds: " + player2FlopOdds);

      if (props.odds.flop.length > 2) {
        player3Wins = props.odds.flop[2].wins;
        player3FlopOdds = (100.0 * player3Wins / props.odds.flop[2].count).toFixed(1);
        console.log("Player 3 Flop Odds: " + player3FlopOdds);
      }

      ++oddsLines;
    }

    if (props.odds.turn && props.odds.turn.length > 0) {
      // console.log("TURN OBJ: " + JSON.stringify(props.odds.turn));
      player1Wins = props.odds.turn[0].wins;
      player2Wins = props.odds.turn[1].wins;

      player1TurnOdds = (100.0 * player1Wins / props.odds.turn[0].count).toFixed(1);
      player2TurnOdds = (100.0 * player2Wins / props.odds.turn[1].count).toFixed(1);
      console.log("Player 1 Turn Odds: " + player1TurnOdds);
      console.log("Player 2 Turn Odds: " + player2TurnOdds);

      if (props.odds.turn.length > 2) {
        player3Wins = props.odds.turn[2].wins;
        player3TurnOdds = (100.0 * player3Wins / props.odds.turn[2].count).toFixed(1);
        console.log("Player 3 Turn Odds: " + player3TurnOdds);
      }

      ++oddsLines;
    }
  }

  const eventLines = (props.event && props.event.length > 0) ? 1 : 0;
  const locationLines = (props.location && props.location.length > 0) ? 1 : 0;

  var canvasHeight = 500 + (1 + textLines.length + oddsLines + (oddsLines > 0 ? 1 : 0) + eventLines + locationLines) * 30.5;

  if (!!blinds && blinds.length > 0) {
    canvasHeight += 42;
  } else if (oddsLines <= 0) {
    canvasHeight += 12;
  }

  if (oddsLines <= 0) {
    canvasHeight += 14;
  } else {
    canvasHeight -= 2;
  }

  console.log("Pre-Width: " + width);
  console.log("Pre-Height: " + canvasHeight);

  var canvasImageWidth = width;
  var canvasImageHeight = canvasHeight;

  if (parseInt(imageShapeValue) === SHAPE_SQUARE) {
    console.log('Image Square');
    canvasImageWidth = Math.max(width, canvasHeight);
    canvasImageHeight = Math.max(width, canvasHeight);
  }

  console.log('Canvas Width  : ' + canvasImageWidth);
  console.log('Canvas Height : ' + canvasImageHeight);

  const canvasXOffset = (canvasImageWidth - width) / 2;
  const canvasYOffset = (canvasImageHeight - canvasHeight) / 2;

  console.log('Canvas X Offset  : ' + canvasXOffset);
  console.log('Canvas Y Offset : ' + canvasYOffset);

  const updateImage = () => {
    console.log("updateImage");

    if (!canvasRef || !canvasRef.current) {
      return;
    }

    if (pngTimer) {
      console.log("Clear PNG Timer");
      clearTimeout(pngTimer);
      pngTimer = undefined;
    }

    var context = canvasRef.current.getContext("2d");

    // We create a new imageData.
    var imageData = context.createImageData(canvasImageWidth, canvasImageHeight);

    // The property data will contain an array of int8
    //var data = imageData.data;

    // we put this random image in the context
    context.putImageData(imageData, 0, 0);

    // Square background
    if (SHAPE_SQUARE === parseInt(imageShapeValue)) {
      context.fillStyle = borderColor;
      context.fillRect(0,0,canvasImageWidth,canvasImageHeight);
      context.stroke();
    }

    context.fillStyle = backgroundColor;
    context.fillRect(canvasXOffset,canvasYOffset,width,canvasHeight);
    context.stroke();

    // Border
    context.lineWidth = 4;
    context.strokeStyle = borderColor;
    context.rect(canvasXOffset,canvasYOffset,width,canvasHeight);
    context.stroke();

    var player1Winner = false;
    var player2Winner = false;
    var player3Winner = false;

    if (props.odds && props.odds.river && props.odds.river[0] && props.odds.river[1]) {
      if (props.odds.river[0].wins || props.odds.river[0].ties) {
        player1Winner = true;
      }

      if (props.odds.river[1].wins || props.odds.river[1].ties) {
        player2Winner = true;
      }

      if (props.odds.river.length > 2) {
        if (props.odds.river[2].wins || props.odds.river[2].ties) {
          player3Winner = true;
        }
      }
    }

    const cardOrigWidth = 180;
    const cardOrigHeight = 251;
    const boardCardScale = 0.64;
    const playerCardScale = 0.72;
    const boardCardWidth = boardCardScale * cardOrigWidth;
    const boardCardHeight = boardCardScale * cardOrigHeight;
    const playerCardWidth = playerCardScale * cardOrigWidth;
    const playerCardHeight = playerCardScale * cardOrigHeight;
    const cardStartPosition = 16.5;
    const cardSpace = 120;
    var playerCardXStart = 18;
    var playerCardSpace = 132;
    var nameCenterOffset = 112;

    var yOffset = 22.5;
    var eventBuffer = false;

    if (props.event && props.event.length > 0) {
      context.fillStyle = fontColor;
      context.textAlign = "center";
      context.font = "36px Arial";
      var eventFontHeight = 36;

      if (props.event.length > 34) {
        context.font = "30px Arial";
        eventFontHeight = 30;
      }
      if (props.event.length > 42) {
        context.font = "24px Arial";
        eventFontHeight = 24;
      }
      const eventTextYOffset = canvasYOffset + yOffset + 18;

      const eventX = 314 + canvasXOffset;

      context.fillText(props.event, 314 + canvasXOffset, eventTextYOffset, width - 40);

      const eventClickBoxX = eventX - width / 2;
      const eventClickBoxY = eventTextYOffset - eventFontHeight;
      const eventClickBoxWidth = width;
      const eventClickBoxHeight = eventFontHeight + 5;

      props.addClickMap({
        id: 'event',
        x: eventClickBoxX,
        y: eventClickBoxY,
        width: eventClickBoxWidth,
        height: eventClickBoxHeight
      });

      if (redLines) {
        context.lineWidth = 2;
        context.strokeStyle = '#FF0000';
        context.rect(eventClickBoxX, eventClickBoxY, eventClickBoxWidth, eventClickBoxHeight);
        context.stroke();
      }
      
      yOffset += 30;
      eventBuffer = true;
    }

    if (props.location && props.location.length > 0) {
      context.fillStyle = fontColor;
      context.textAlign = "center";
      context.font = "28px Arial";
      var locationFontHeight = 28;

      if (props.event.length > 42) {
        context.font = "24px Arial";
        locationFontHeight = 24;
      }

      const locationTextYOffset = canvasYOffset + yOffset + 18;

      const locationX = 314 + canvasXOffset;
      const locationY = locationTextYOffset;
      const locationWidth = width - 40;

      context.fillText(props.location, locationX, locationY, locationWidth);

      const locationClickBoxX = locationX - width / 2;
      const locationClickBoxY = locationY - locationFontHeight + 5;
      const locationClickBoxWidth = width;
      const locationClickBoxHeight = locationFontHeight + 5;

      props.addClickMap({
        id: 'location',
        x: locationClickBoxX,
        y: locationClickBoxY,
        width: locationClickBoxWidth,
        height: locationClickBoxHeight
      });

      if (redLines) {
        context.lineWidth = 2;
        context.strokeStyle = '#FF0000';
        context.rect(locationClickBoxX, locationClickBoxY, locationClickBoxWidth, locationClickBoxHeight);
        context.stroke();
      }

      yOffset += 30;
      eventBuffer = true;
    }

    if (eventBuffer) {
      yOffset += 8;
    }

    const boardOffsetY = yOffset;

    const player1DisplayName = props.player1Name;// + (props.player1Position ? " (" + props.player1Position + ")" : "");
    const player2DisplayName = props.player2Name;// + (props.player2Position ? " (" + props.player2Position + ")" : "");
    const player3DisplayName = props.player3Name;// + (props.player2Position ? " (" + props.player2Position + ")" : "");

    const player1DisplayChips = props.player1Chips + (props.player1Position ? " " + props.player1Position : "");
    const player2DisplayChips = props.player2Chips + (props.player2Position ? " " + props.player2Position : "");
    const player3DisplayChips = props.player3Chips + (props.player3Position ? " " + props.player3Position : "");

    var cardImage1 = new Image();
    cardImage1.crossOrigin = "Anonymous";
    cardImage1.src = boardCard1Image;
    cardImage1.onload = () => {
      const cardImage1X = canvasXOffset + cardStartPosition;
      const cardImage1Y = canvasYOffset + boardOffsetY;
      const cardImage1Width = boardCardWidth;
      const cardImage1Height = boardCardHeight;
      context.drawImage(cardImage1, cardImage1X, cardImage1Y, cardImage1Width, cardImage1Height);

      props.addClickMap({
        id: 'cardImage1',
        x: cardImage1X,
        y: cardImage1Y,
        width: cardImage1Width,
        height: cardImage1Height
      });

      if (redLines) {
        context.lineWidth = 2;
        context.strokeStyle = '#FF0000';
        context.rect(cardImage1X, cardImage1Y, cardImage1Width, cardImage1Height);
        context.stroke();
      }
    };

    var cardImage2 = new Image();
    cardImage2.crossOrigin = "Anonymous";
    cardImage2.src = boardCard2Image;
    cardImage2.onload = () => {
      const cardImage2X = canvasXOffset + cardStartPosition + cardSpace * 1;
      const cardImage2Y = canvasYOffset + boardOffsetY;
      const cardImage2Width = boardCardWidth;
      const cardImage2Height = boardCardHeight;

      context.drawImage(cardImage2, cardImage2X, cardImage2Y, cardImage2Width, cardImage2Height);

      props.addClickMap({
        id: 'cardImage2',
        x: cardImage2X,
        y: cardImage2Y,
        width: cardImage2Width,
        height: cardImage2Height
      });

      if (redLines) {
        context.lineWidth = 2;
        context.strokeStyle = '#FF0000';
        context.rect(cardImage2X, cardImage2Y, cardImage2Width, cardImage2Height);
        context.stroke();
      }
    };

    var cardImage3 = new Image();
    cardImage3.crossOrigin = "Anonymous";
    cardImage3.src = boardCard3Image;
    cardImage3.onload = () => {
      const cardImage3X = canvasXOffset + cardStartPosition + cardSpace * 2;
      const cardImage3Y = canvasYOffset + boardOffsetY;
      const cardImage3Width = boardCardWidth;
      const cardImage3Height = boardCardHeight;

      context.drawImage(cardImage3, cardImage3X, cardImage3Y, cardImage3Width, cardImage3Height);

      props.addClickMap({
        id: 'cardImage3',
        x: cardImage3X,
        y: cardImage3Y,
        width: cardImage3Width,
        height: cardImage3Height
      });

      if (redLines) {
        context.lineWidth = 2;
        context.strokeStyle = '#FF0000';
        context.rect(cardImage3X, cardImage3Y, cardImage3Width, cardImage3Height);
        context.stroke();
      }
    };

    var cardImage4 = new Image();
    cardImage4.crossOrigin = "Anonymous";
    cardImage4.src = boardCard4Image;
    cardImage4.onload = () => {
      const cardImage4X = canvasXOffset + cardStartPosition + cardSpace * 3;
      const cardImage4Y = canvasYOffset + boardOffsetY;
      const cardImage4Width = boardCardWidth;
      const cardImage4Height = boardCardHeight;

      context.drawImage(cardImage4, cardImage4X, cardImage4Y, cardImage4Width, cardImage4Height);

      props.addClickMap({
        id: 'cardImage4',
        x: cardImage4X,
        y: cardImage4Y,
        width: cardImage4Width,
        height: cardImage4Height
      });

      if (redLines) {
        context.lineWidth = 2;
        context.strokeStyle = '#FF0000';
        context.rect(cardImage4X, cardImage4Y, cardImage4Width, cardImage4Height);
        context.stroke();
      }
    };

    var cardImage5 = new Image();
    cardImage5.crossOrigin = "Anonymous";
    cardImage5.src = boardCard5Image;
    cardImage5.onload = () => {
      const cardImage5X = canvasXOffset + cardStartPosition + cardSpace * 4;
      const cardImage5Y = canvasYOffset + boardOffsetY;
      const cardImage5Width = boardCardWidth;
      const cardImage5Height = boardCardHeight;

      context.drawImage(cardImage5, cardImage5X, cardImage5Y, cardImage5Width, cardImage5Height);

      props.addClickMap({
        id: 'cardImage5',
        x: cardImage5X,
        y: cardImage5Y,
        width: cardImage5Width,
        height: cardImage5Height
      });

      if (redLines) {
        context.lineWidth = 2;
        context.strokeStyle = '#FF0000';
        context.rect(cardImage5X, cardImage5Y, cardImage5Width, cardImage5Height);
        context.stroke();
      }
    };

    context.fillStyle = fontColor;
    context.textAlign = "center";
    context.font = "20px Arial";
    context.fillText("FLOP", canvasXOffset + 195, canvasYOffset + boardOffsetY + 180);
    context.fillText("TURN", canvasXOffset + 436, canvasYOffset + boardOffsetY + 180);
    context.fillText("RIVER", canvasXOffset + 557, canvasYOffset + boardOffsetY + 180);

    // PLAYERS
    console.log('type player3Enabled: ' + typeof(player3Enabled));
    console.log('player3Enabled: ' + player3Enabled);

    if ('false' === player3Enabled || !player3Enabled) {

      var playerOffsetX1 = 55;
      const playerOffsetX2 = playerOffsetX1 + 295;

      var playerOffsetY = yOffset + 235;

      // Player 1
      var player1Card1 = new Image();
      player1Card1.crossOrigin = "Anonymous";
      player1Card1.src = player1Card1Image;
      player1Card1.onload = () => {
        const player1Card1X = canvasXOffset + playerOffsetX1 - playerCardXStart;
        const player1Card1Y = canvasYOffset + playerOffsetY + 38;

        context.drawImage(player1Card1, player1Card1X, player1Card1Y, playerCardWidth, playerCardHeight);

        props.addClickMap({
          id: 'player1Card1',
          x: player1Card1X,
          y: player1Card1Y,
          width: playerCardWidth,
          height: playerCardHeight
        });
  
        if (redLines) {
          context.lineWidth = 2;
          context.strokeStyle = '#FF0000';
          context.rect(player1Card1X, player1Card1Y, playerCardWidth, playerCardHeight);
          context.stroke();
        }
      };

      var player1Card2 = new Image();
      player1Card2.crossOrigin = "Anonymous";
      player1Card2.src = player1Card2Image;
      player1Card2.onload = () => {
        const player1Card2X = canvasXOffset + playerOffsetX1 - playerCardXStart + playerCardSpace;
        const player1Card2Y = canvasYOffset + playerOffsetY + 38;

        context.drawImage(player1Card2, player1Card2X, player1Card2Y, playerCardWidth, playerCardHeight);

        props.addClickMap({
          id: 'player1Card2',
          x: player1Card2X,
          y: player1Card2Y,
          width: playerCardWidth,
          height: playerCardHeight
        });

        if (redLines) {
          context.lineWidth = 2;
          context.strokeStyle = '#FF0000';
          context.rect(player1Card2X, player1Card2Y, playerCardWidth, playerCardHeight);
          context.stroke();
        }
      };

      context.fillStyle = fontColor;
      context.font = "36px Arial";

      const player1DisplayNameX = canvasXOffset + playerOffsetX1 + nameCenterOffset;
      const player1DisplayNameY = canvasYOffset + playerOffsetY;
      const player1DisplayNameWidth = width / 2 - 40;

      context.fillText(player1DisplayName, player1DisplayNameX, player1DisplayNameY, player1DisplayNameWidth);

      if (player1Winner) {
        context.drawImage(checkElm, canvasXOffset + playerOffsetX1 + 215, canvasYOffset + playerOffsetY + 9, 25, 25);
      }

      const player1DisplayNameClickBoxX = player1DisplayNameX - player1DisplayNameWidth / 2;
      const player1DisplayNameClickBoxY = player1DisplayNameY - 36;
      const player1DisplayNameClickBoxWidth = player1DisplayNameWidth;
      const player1DisplayNameClickBoxHeight = 41;

      props.addClickMap({
        id: 'player1DisplayName',
        x: player1DisplayNameClickBoxX,
        y: player1DisplayNameClickBoxY,
        width: player1DisplayNameClickBoxWidth,
        height: player1DisplayNameClickBoxHeight
      });

      if (redLines) {
        context.lineWidth = 2;
        context.strokeStyle = '#FF0000';
        context.rect(player1DisplayNameClickBoxX, player1DisplayNameClickBoxY, player1DisplayNameClickBoxWidth, player1DisplayNameClickBoxHeight);
        context.stroke();
      }

      context.fillStyle = fontColor;
      context.font = "24px Arial";

      const player1DisplayChipsX = canvasXOffset + playerOffsetX1 + nameCenterOffset;
      const player1DisplayChipsY = canvasYOffset + playerOffsetY + 29;
      const player1DisplayChipsWidth = width / 2 - 40;

      context.fillText(player1DisplayChips, player1DisplayChipsX, player1DisplayChipsY);

      const player1DisplayChipsClickBoxX = player1DisplayChipsX - player1DisplayChipsWidth / 2;
      const player1DisplayChipsClickBoxY = player1DisplayChipsY - 24;
      const player1DisplayChipsClickBoxWidth = player1DisplayChipsWidth;
      const player1DisplayChipsClickBoxHeight = 29;

      props.addClickMap({
        id: 'player1DisplayChips',
        x: player1DisplayChipsClickBoxX,
        y: player1DisplayChipsClickBoxY,
        width: player1DisplayChipsClickBoxWidth,
        height: player1DisplayChipsClickBoxHeight
      });

      if (redLines) {
        context.lineWidth = 2;
        context.strokeStyle = '#FF0000';
        context.rect(player1DisplayChipsClickBoxX, player1DisplayChipsClickBoxY, player1DisplayChipsClickBoxWidth, player1DisplayChipsClickBoxHeight);
        context.stroke();
      }

      // Player 2

      var player2Card1 = new Image();
      player2Card1.crossOrigin = "Anonymous";
      player2Card1.src = player2Card1Image;
      player2Card1.onload = () => {
        const player2Card1X = canvasXOffset + playerOffsetX2 - playerCardXStart;
        const player2Card1Y = canvasYOffset + playerOffsetY + 38;

        context.drawImage(player2Card1, player2Card1X, player2Card1Y, playerCardWidth, playerCardHeight);

        props.addClickMap({
          id: 'player2Card1',
          x: player2Card1X,
          y: player2Card1Y,
          width: playerCardWidth,
          height: playerCardHeight
        });
  
        if (redLines) {
          context.lineWidth = 2;
          context.strokeStyle = '#FF0000';
          context.rect(player2Card1X, player2Card1Y, playerCardWidth, playerCardHeight);
          context.stroke();
        }
      };

      var player2Card2 = new Image();
      player2Card2.crossOrigin = "Anonymous";
      player2Card2.src = player2Card2Image;
      player2Card2.onload = () => {
        const player2Card2X = canvasXOffset + playerOffsetX2 - playerCardXStart + playerCardSpace;
        const player2Card2Y = canvasYOffset + playerOffsetY + 38;

        context.drawImage(player2Card2, player2Card2X, player2Card2Y, playerCardWidth, playerCardHeight);

        props.addClickMap({
          id: 'player2Card2',
          x: player2Card2X,
          y: player2Card2Y,
          width: playerCardWidth,
          height: playerCardHeight
        });

        if (redLines) {
          context.lineWidth = 2;
          context.strokeStyle = '#FF0000';
          context.rect(player2Card2X, player2Card2Y, playerCardWidth, playerCardHeight);
          context.stroke();
        }
      };

      context.fillStyle = fontColor;
      context.font = "36px Arial";

      const player2DisplayNameX = canvasXOffset + playerOffsetX2 + nameCenterOffset;
      const player2DisplayNameY = canvasYOffset + playerOffsetY;
      const player2DisplayNameWidth = width / 2 - 40;

      context.fillText(player2DisplayName, player2DisplayNameX, player2DisplayNameY, player2DisplayNameWidth);

      if (player2Winner) {
        context.drawImage(checkElm, canvasXOffset + playerOffsetX2 + 215, canvasYOffset + playerOffsetY + 9, 25, 25);
      }

      const player2DisplayNameClickBoxX = player2DisplayNameX - player2DisplayNameWidth / 2;
      const player2DisplayNameClickBoxY = player2DisplayNameY - 36;
      const player2DisplayNameClickBoxWidth = player2DisplayNameWidth;
      const player2DisplayNameClickBoxHeight = 41;

      props.addClickMap({
        id: 'player2DisplayName',
        x: player2DisplayNameClickBoxX,
        y: player2DisplayNameClickBoxY,
        width: player2DisplayNameClickBoxWidth,
        height: player2DisplayNameClickBoxHeight
      });

      if (redLines) {
        context.lineWidth = 2;
        context.strokeStyle = '#FF0000';
        context.rect(player2DisplayNameClickBoxX, player2DisplayNameClickBoxY, player2DisplayNameClickBoxWidth, player2DisplayNameClickBoxHeight);
        context.stroke();
      }

      context.fillStyle = fontColor;
      context.font = "24px Arial";

      const player2DisplayChipsX = canvasXOffset + playerOffsetX2 + nameCenterOffset;
      const player2DisplayChipsY = canvasYOffset + playerOffsetY + 29;
      const player2DisplayChipsWidth = width / 2 - 40;

      context.fillText(player2DisplayChips, player2DisplayChipsX, player2DisplayChipsY);

      const player2DisplayChipsClickBoxX = player2DisplayChipsX - player2DisplayChipsWidth / 2;
      const player2DisplayChipsClickBoxY = player2DisplayChipsY - 24;
      const player2DisplayChipsClickBoxWidth = player2DisplayChipsWidth;
      const player2DisplayChipsClickBoxHeight = 29;

      props.addClickMap({
        id: 'player2DisplayChips',
        x: player2DisplayChipsClickBoxX,
        y: player2DisplayChipsClickBoxY,
        width: player2DisplayChipsClickBoxWidth,
        height: player2DisplayChipsClickBoxHeight
      });

      if (redLines) {
        context.lineWidth = 2;
        context.strokeStyle = '#FF0000';
        context.rect(player2DisplayChipsClickBoxX, player2DisplayChipsClickBoxY, player2DisplayChipsClickBoxWidth, player2DisplayChipsClickBoxHeight);
        context.stroke();
      }

      context.fillStyle = fontColor;
      context.textAlign = "left";
      context.font = "24px Arial";

      yOffset = playerOffsetY + 250

      var preFlopOddsText1 = '';
      var preFlopOddsText2 = '';

      if (player1PreFlopOdds && player2PreFlopOdds) {
        preFlopOddsText1 += player1PreFlopOdds + " %";
        preFlopOddsText2 += player2PreFlopOdds + " %";
      }

      var flopOddsText1 = '';
      var flopOddsText2 = '';

      if (player1FlopOdds && player2FlopOdds) {
        flopOddsText1 += player1FlopOdds + " %";
        flopOddsText2 += player2FlopOdds + " %";
      }

      var turnOddsText1 = '';
      var turnOddsText2 = '';

      if (player1TurnOdds && player2TurnOdds) {
        turnOddsText1 += player1TurnOdds + " %";
        turnOddsText2 += player2TurnOdds + " %";
      }

      const oddsX1 = playerOffsetX1 + 116;
      const oddsX2 = playerOffsetX2 + 116;
      var oddsIncluded = false;

      if (preFlopOddsText1.length > 0 && preFlopOddsText2.length > 0) {
        context.textAlign = "left";

        context.fillText(preFlopOddsText1, canvasXOffset + oddsX1 + 7, canvasYOffset + yOffset);
        context.fillText(preFlopOddsText2, canvasXOffset + oddsX2 + 7, canvasYOffset + yOffset);

        context.textAlign = "right";

        context.fillText("Pre-Flop :", canvasXOffset + oddsX1, canvasYOffset + yOffset);
        context.fillText("Pre-Flop :", canvasXOffset + oddsX2, canvasYOffset + yOffset);

        yOffset += 30;
        oddsIncluded = true;
      }

      if (flopOddsText1.length > 0 && flopOddsText2.length > 0) {
        context.textAlign = "left";

        context.fillText(flopOddsText1, canvasXOffset + oddsX1 + 7, canvasYOffset + yOffset);
        context.fillText(flopOddsText2, canvasXOffset + oddsX2 + 7, canvasYOffset + yOffset);

        context.textAlign = "right";

        context.fillText("Flop :", canvasXOffset + oddsX1, canvasYOffset + yOffset);
        context.fillText("Flop :", canvasXOffset + oddsX2, canvasYOffset + yOffset);

        yOffset += 30;
        oddsIncluded = true;
      }

      if (turnOddsText1.length > 0 && turnOddsText2.length > 0) {
        context.textAlign = "left";

        context.fillText(turnOddsText1, canvasXOffset + oddsX1 + 7, canvasYOffset + yOffset);
        context.fillText(turnOddsText2, canvasXOffset + oddsX2 + 7, canvasYOffset + yOffset);

        context.textAlign = "right";

        context.fillText("Turn :", canvasXOffset + oddsX1, canvasYOffset + yOffset);
        context.fillText("Turn :", canvasXOffset + oddsX2, canvasYOffset + yOffset);

        yOffset += 30;
        oddsIncluded = true;
      }

      if (oddsIncluded) {
        yOffset += 30;
      }
    } else {
      // 3 PLAYERS

      playerOffsetX1 = 0;
      const playerOffsetX2 = playerOffsetX1 + 200;
      const playerOffsetX3 = playerOffsetX2 + 200;
      const winXOffset = 160;
      const winYOffset = 40;

      playerOffsetY = yOffset + 235;

      playerCardSpace = 50;
      playerCardXStart = -24;

      // Player 1
      const player1Cards1ClickBoxX = canvasXOffset + playerOffsetX1 - playerCardXStart;
      const player1Cards1ClickBoxY = canvasYOffset + playerOffsetY + 38;
      const player1Cards1ClickBoxWidth = (playerCardWidth + playerCardSpace) / 2;
      const player1Cards1ClickBoxHeight = playerCardHeight;

      player1Card1 = new Image();
      player1Card1.crossOrigin = "Anonymous";
      player1Card1.src = player1Card1Image;
      player1Card1.onload = () => {
        context.drawImage(player1Card1, canvasXOffset + playerOffsetX1 - playerCardXStart, canvasYOffset + playerOffsetY + 38, playerCardWidth, playerCardHeight);
        if (player1Winner) {
          context.drawImage(checkElm, canvasXOffset + playerOffsetX1 + winXOffset, canvasYOffset + playerOffsetY + winYOffset, 25, 25);
        }

        props.addClickMap({
          id: 'player1Card1',
          x: player1Cards1ClickBoxX,
          y: player1Cards1ClickBoxY,
          width: player1Cards1ClickBoxWidth,
          height: player1Cards1ClickBoxHeight - 5
        });  

        if (redLines) {
          context.lineWidth = 2;
          context.strokeStyle = '#FF0000';
          context.rect(player1Cards1ClickBoxX, player1Cards1ClickBoxY, player1Cards1ClickBoxWidth, player1Cards1ClickBoxHeight);
          context.stroke();  
        }

      };

      const player1Cards2ClickBoxX = canvasXOffset + playerOffsetX1 - playerCardXStart + player1Cards1ClickBoxWidth;
      const player1Cards2ClickBoxY = canvasYOffset + playerOffsetY + 38;
      const player1Cards2ClickBoxWidth = (playerCardWidth + playerCardSpace) / 2;
      const player1Cards2ClickBoxHeight = playerCardHeight;

      player1Card2 = new Image();
      player1Card2.crossOrigin = "Anonymous";
      player1Card2.src = player1Card2Image;
      player1Card2.onload = () => {
        context.drawImage(player1Card2, canvasXOffset + playerOffsetX1 - playerCardXStart + playerCardSpace, canvasYOffset + playerOffsetY + 38, playerCardWidth, playerCardHeight);
        if (player1Winner) {
          context.drawImage(checkElm, canvasXOffset + playerOffsetX1 + winXOffset, canvasYOffset + playerOffsetY + winYOffset, 25, 25);
        }

        props.addClickMap({
          id: 'player1Card2',
          x: player1Cards2ClickBoxX,
          y: player1Cards2ClickBoxY,
          width: player1Cards2ClickBoxWidth,
          height: player1Cards2ClickBoxHeight - 5
        });  
  
        if (redLines) {
          context.lineWidth = 2;
          context.strokeStyle = '#FF0000';
          context.rect(player1Cards2ClickBoxX, player1Cards2ClickBoxY, player1Cards2ClickBoxWidth, player1Cards2ClickBoxHeight);
          context.stroke();  
        }
      };

      context.fillStyle = fontColor;
      context.font = "36px Arial";

      const player1DisplayNameX = canvasXOffset + playerOffsetX1 + nameCenterOffset;
      const player1DisplayNameY = canvasYOffset + playerOffsetY;
      const player1DisplayNameWidth = width / 3 - 20;

      context.fillText(player1DisplayName, player1DisplayNameX, player1DisplayNameY, player1DisplayNameWidth);
      
      const player1DisplayNameClickBoxX = player1DisplayNameX - player1DisplayNameWidth / 2;
      const player1DisplayNameClickBoxY = player1DisplayNameY - 36;
      const player1DisplayNameClickBoxWidth = player1DisplayNameWidth;
      const player1DisplayNameClickBoxHeight = 41;

      props.addClickMap({
        id: 'player1DisplayName',
        x: player1DisplayNameClickBoxX,
        y: player1DisplayNameClickBoxY,
        width: player1DisplayNameClickBoxWidth,
        height: player1DisplayNameClickBoxHeight
      });

      if (redLines) {
        context.lineWidth = 2;
        context.strokeStyle = '#FF0000';
        context.rect(player1DisplayNameClickBoxX, player1DisplayNameClickBoxY, player1DisplayNameClickBoxWidth, player1DisplayNameClickBoxHeight);
        context.stroke();
      }

      context.fillStyle = fontColor;
      context.font = "24px Arial";

      const player1DisplayChipsX = canvasXOffset + playerOffsetX1 + nameCenterOffset;
      const player1DisplayChipsY = canvasYOffset + playerOffsetY + 29;
      const player1DisplayChipsWidth = width / 3 - 20;

      context.fillText(player1DisplayChips, player1DisplayChipsX, player1DisplayChipsY, player1DisplayChipsWidth);

      const player1DisplayChipsClickBoxX = player1DisplayChipsX - player1DisplayChipsWidth / 2;
      const player1DisplayChipsClickBoxY = player1DisplayChipsY - 24;
      const player1DisplayChipsClickBoxWidth = player1DisplayChipsWidth;
      const player1DisplayChipsClickBoxHeight = 29;

      props.addClickMap({
        id: 'player1DisplayChips',
        x: player1DisplayChipsClickBoxX,
        y: player1DisplayChipsClickBoxY,
        width: player1DisplayChipsClickBoxWidth,
        height: player1DisplayChipsClickBoxHeight
      });

      if (redLines) {
        context.lineWidth = 2;
        context.strokeStyle = '#FF0000';
        context.rect(player1DisplayChipsClickBoxX, player1DisplayChipsClickBoxY, player1DisplayChipsClickBoxWidth, player1DisplayChipsClickBoxHeight);
        context.stroke();
      }

      // Player 2

      const player2Cards1ClickBoxX = canvasXOffset + playerOffsetX2 - playerCardXStart;
      const player2Cards1ClickBoxY = canvasYOffset + playerOffsetY + 38;
      const player2Cards1ClickBoxWidth = (playerCardWidth + playerCardSpace) / 2;
      const player2Cards1ClickBoxHeight = playerCardHeight;

      player2Card1 = new Image();
      player2Card1.crossOrigin = "Anonymous";
      player2Card1.src = player2Card1Image;
      player2Card1.onload = () => {
        context.drawImage(player2Card1, canvasXOffset + playerOffsetX2 - playerCardXStart, canvasYOffset + playerOffsetY + 38, playerCardWidth, playerCardHeight);
        if (player2Winner) {
          context.drawImage(checkElm, canvasXOffset + playerOffsetX2 + winXOffset, canvasYOffset + playerOffsetY + winYOffset, 25, 25);
        }

        if (redLines) {
          context.lineWidth = 2;
          context.strokeStyle = '#FF0000';
          context.rect(player2Cards1ClickBoxX, player2Cards1ClickBoxY, player2Cards1ClickBoxWidth, player2Cards1ClickBoxHeight);
          context.stroke();  
        }

        props.addClickMap({
          id: 'player2Card1',
          x: player2Cards1ClickBoxX,
          y: player2Cards1ClickBoxY,
          width: player2Cards1ClickBoxWidth,
          height: player2Cards1ClickBoxHeight - 5
        });  

      };

      const player2Cards2ClickBoxX = canvasXOffset + playerOffsetX2 - playerCardXStart + player2Cards1ClickBoxWidth;
      const player2Cards2ClickBoxY = canvasYOffset + playerOffsetY + 38;
      const player2Cards2ClickBoxWidth = (playerCardWidth + playerCardSpace) / 2;
      const player2Cards2ClickBoxHeight = playerCardHeight;

      player2Card2 = new Image();
      player2Card2.crossOrigin = "Anonymous";
      player2Card2.src = player2Card2Image;
      player2Card2.onload = () => {
        context.drawImage(player2Card2, canvasXOffset + playerOffsetX2 - playerCardXStart + playerCardSpace, canvasYOffset + playerOffsetY + 38, playerCardWidth, playerCardHeight);
        if (player2Winner) {
          context.drawImage(checkElm, canvasXOffset + playerOffsetX2 + winXOffset, canvasYOffset + playerOffsetY + winYOffset, 25, 25);
        }

        props.addClickMap({
          id: 'player2Card2',
          x: player2Cards2ClickBoxX,
          y: player2Cards2ClickBoxY,
          width: player2Cards2ClickBoxWidth,
          height: player2Cards2ClickBoxHeight - 5
        });
  
        if (redLines) {
          context.lineWidth = 2;
          context.strokeStyle = '#FF0000';
          context.rect(player2Cards2ClickBoxX, player2Cards2ClickBoxY, player2Cards2ClickBoxWidth, player2Cards2ClickBoxHeight);
          context.stroke();  
        }
      };

      context.fillStyle = fontColor;
      context.font = "36px Arial";

      const player2DisplayNameX = canvasXOffset + playerOffsetX2 + nameCenterOffset;
      const player2DisplayNameY = canvasYOffset + playerOffsetY;
      const player2DisplayNameWidth = width / 3 - 20;

      context.fillText(player2DisplayName, player2DisplayNameX, player2DisplayNameY, player2DisplayNameWidth);

      const player2DisplayNameClickBoxX = player2DisplayNameX - player2DisplayNameWidth / 2;
      const player2DisplayNameClickBoxY = player2DisplayNameY - 36;
      const player2DisplayNameClickBoxWidth = player2DisplayNameWidth;
      const player2DisplayNameClickBoxHeight = 41;

      props.addClickMap({
        id: 'player2DisplayName',
        x: player2DisplayNameClickBoxX,
        y: player2DisplayNameClickBoxY,
        width: player2DisplayNameClickBoxWidth,
        height: player2DisplayNameClickBoxHeight
      });

      if (redLines) {
        context.lineWidth = 2;
        context.strokeStyle = '#FF0000';
        context.rect(player2DisplayNameClickBoxX, player2DisplayNameClickBoxY, player2DisplayNameClickBoxWidth, player2DisplayNameClickBoxHeight);
        context.stroke();
      }

      context.fillStyle = fontColor;
      context.font = "24px Arial";

      const player2DisplayChipsX = canvasXOffset + playerOffsetX2 + nameCenterOffset;
      const player2DisplayChipsY = canvasYOffset + playerOffsetY + 29;
      const player2DisplayChipsWidth = width / 3 - 20;

      context.fillText(player2DisplayChips, player2DisplayChipsX, player2DisplayChipsY, player2DisplayChipsWidth);

      const player2DisplayChipsClickBoxX = player2DisplayChipsX - player2DisplayChipsWidth / 2;
      const player2DisplayChipsClickBoxY = player2DisplayChipsY - 24;
      const player2DisplayChipsClickBoxWidth = player2DisplayChipsWidth;
      const player2DisplayChipsClickBoxHeight = 29;

      props.addClickMap({
        id: 'player2DisplayChips',
        x: player2DisplayChipsClickBoxX,
        y: player2DisplayChipsClickBoxY,
        width: player2DisplayChipsClickBoxWidth,
        height: player2DisplayChipsClickBoxHeight
      });

      if (redLines) {
        context.lineWidth = 2;
        context.strokeStyle = '#FF0000';
        context.rect(player2DisplayChipsClickBoxX, player2DisplayChipsClickBoxY, player2DisplayChipsClickBoxWidth, player2DisplayChipsClickBoxHeight);
        context.stroke();
      }

      console.log('player3 winner: ' + player3Winner);
      // Player 3

      const player3Cards1ClickBoxX = canvasXOffset + playerOffsetX3 - playerCardXStart;
      const player3Cards1ClickBoxY = canvasYOffset + playerOffsetY + 38;
      const player3Cards1ClickBoxWidth = (playerCardWidth + playerCardSpace) / 2;
      const player3Cards1ClickBoxHeight = playerCardHeight;

      var player3Card1 = new Image();
      player3Card1.crossOrigin = "Anonymous";
      player3Card1.src = player3Card1Image;
      player3Card1.onload = () => {
        context.drawImage(player3Card1, canvasXOffset + playerOffsetX3 - playerCardXStart, canvasYOffset + playerOffsetY + 38, playerCardWidth, playerCardHeight);
        if (player3Winner) {
          context.drawImage(checkElm, canvasXOffset + playerOffsetX3 + winXOffset, canvasYOffset + playerOffsetY + winYOffset, 25, 25);
        }

        props.addClickMap({
          id: 'player3Card1',
          x: player3Cards1ClickBoxX,
          y: player3Cards1ClickBoxY,
          width: player3Cards1ClickBoxWidth,
          height: player3Cards1ClickBoxHeight - 5
        });
  
        if (redLines) {
          context.lineWidth = 2;
          context.strokeStyle = '#FF0000';
          context.rect(player3Cards1ClickBoxX, player3Cards1ClickBoxY, player3Cards1ClickBoxWidth, player3Cards1ClickBoxHeight);
          context.stroke();  
        }

      };

      const player3Cards2ClickBoxX = canvasXOffset + playerOffsetX3 - playerCardXStart + player3Cards1ClickBoxWidth;
      const player3Cards2ClickBoxY = canvasYOffset + playerOffsetY + 38;
      const player3Cards2ClickBoxWidth = (playerCardWidth + playerCardSpace) / 2;
      const player3Cards2ClickBoxHeight = playerCardHeight;
      
      var player3Card2 = new Image();
      player3Card2.crossOrigin = "Anonymous";
      player3Card2.src = player3Card2Image;
      player3Card2.onload = () => {
        context.drawImage(player3Card2, canvasXOffset + playerOffsetX3 - playerCardXStart + playerCardSpace, canvasYOffset + playerOffsetY + 38, playerCardWidth, playerCardHeight);
        if (player3Winner) {
          context.drawImage(checkElm, canvasXOffset + playerOffsetX3 + winXOffset, canvasYOffset + playerOffsetY + winYOffset, 25, 25);
        }

        props.addClickMap({
          id: 'player3Card2',
          x: player3Cards2ClickBoxX,
          y: player3Cards2ClickBoxY,
          width: player3Cards2ClickBoxWidth,
          height: player3Cards2ClickBoxHeight - 5
        });
  
        if (redLines) {
          context.lineWidth = 2;
          context.strokeStyle = '#FF0000';
          context.rect(player3Cards2ClickBoxX, player3Cards2ClickBoxY, player3Cards2ClickBoxWidth, player3Cards2ClickBoxHeight);
          context.stroke();  
        }
      };

      context.fillStyle = fontColor;
      context.font = "36px Arial";

      const player3DisplayNameX = canvasXOffset + playerOffsetX3 + nameCenterOffset;
      const player3DisplayNameY = canvasYOffset + playerOffsetY;
      const player3DisplayNameWidth = width / 3 - 20;

      context.fillText(player3DisplayName, player3DisplayNameX, player3DisplayNameY, player3DisplayNameWidth);

      const player3DisplayNameClickBoxX = player3DisplayNameX - player3DisplayNameWidth / 2;
      const player3DisplayNameClickBoxY = player3DisplayNameY - 36;
      const player3DisplayNameClickBoxWidth = player3DisplayNameWidth;
      const player3DisplayNameClickBoxHeight = 41;

      props.addClickMap({
        id: 'player3DisplayName',
        x: player3DisplayNameClickBoxX,
        y: player3DisplayNameClickBoxY,
        width: player3DisplayNameClickBoxWidth,
        height: player3DisplayNameClickBoxHeight
      });

      if (redLines) {
        context.lineWidth = 2;
        context.strokeStyle = '#FF0000';
        context.rect(player3DisplayNameClickBoxX, player3DisplayNameClickBoxY, player3DisplayNameClickBoxWidth, player3DisplayNameClickBoxHeight);
        context.stroke();
      }

      context.fillStyle = fontColor;
      context.font = "24px Arial";

      const player3DisplayChipsX = canvasXOffset + playerOffsetX3 + nameCenterOffset;
      const player3DisplayChipsY = canvasYOffset + playerOffsetY + 29;
      const player3DisplayChipsWidth = width / 3 - 20;

      context.fillText(player3DisplayChips, player3DisplayChipsX, player3DisplayChipsY, player3DisplayNameWidth);

      const player3DisplayChipsClickBoxX = player3DisplayChipsX - player3DisplayChipsWidth / 2;
      const player3DisplayChipsClickBoxY = player3DisplayChipsY - 24;
      const player3DisplayChipsClickBoxWidth = player3DisplayChipsWidth;
      const player3DisplayChipsClickBoxHeight = 29;

      props.addClickMap({
        id: 'player3DisplayChips',
        x: player3DisplayChipsClickBoxX,
        y: player3DisplayChipsClickBoxY,
        width: player3DisplayChipsClickBoxWidth,
        height: player3DisplayChipsClickBoxHeight
      });

      if (redLines) {
        context.lineWidth = 2;
        context.strokeStyle = '#FF0000';
        context.rect(player3DisplayChipsClickBoxX, player3DisplayChipsClickBoxY, player3DisplayChipsClickBoxWidth, player3DisplayChipsClickBoxHeight);
        context.stroke();
      }

      // ODDS

      context.fillStyle = fontColor;
      context.textAlign = "left";
      context.font = "24px Arial";

      yOffset = playerOffsetY + 250

      preFlopOddsText1 = '';
      preFlopOddsText2 = '';
      var preFlopOddsText3 = '';

      if (player1PreFlopOdds && player2PreFlopOdds && player3PreFlopOdds) {
        preFlopOddsText1 += player1PreFlopOdds + " %";
        preFlopOddsText2 += player2PreFlopOdds + " %";
        preFlopOddsText3 += player3PreFlopOdds + " %";
      }

      flopOddsText1 = '';
      flopOddsText2 = '';
      var flopOddsText3 = '';

      if (player1FlopOdds && player2FlopOdds && player3PreFlopOdds) {
        flopOddsText1 += player1FlopOdds + " %";
        flopOddsText2 += player2FlopOdds + " %";
        flopOddsText3 += player3FlopOdds + " %";
      }

      turnOddsText1 = '';
      turnOddsText2 = '';
      var turnOddsText3 = '';

      if (player1TurnOdds && player2TurnOdds && player3TurnOdds) {
        turnOddsText1 += player1TurnOdds + " %";
        turnOddsText2 += player2TurnOdds + " %";
        turnOddsText3 += player3TurnOdds + " %";
      }

      const oddsOffset = 103;
      const oddsX1 = playerOffsetX1 + oddsOffset;
      const oddsX2 = playerOffsetX2 + oddsOffset;
      const oddsX3 = playerOffsetX3 + oddsOffset;
      oddsIncluded = false;

      if (preFlopOddsText1.length > 0 && preFlopOddsText2.length > 0 && preFlopOddsText3.length > 0) {
        context.textAlign = "left";

        context.fillText(preFlopOddsText1, canvasXOffset + oddsX1 + 7, canvasYOffset + yOffset);
        context.fillText(preFlopOddsText2, canvasXOffset + oddsX2 + 7, canvasYOffset + yOffset);
        context.fillText(preFlopOddsText3, canvasXOffset + oddsX3 + 7, canvasYOffset + yOffset);

        context.textAlign = "right";

        context.fillText("Pre :", canvasXOffset + oddsX1, canvasYOffset + yOffset);
        context.fillText("Pre :", canvasXOffset + oddsX2, canvasYOffset + yOffset);
        context.fillText("Pre :", canvasXOffset + oddsX3, canvasYOffset + yOffset);

        yOffset += 30;
        oddsIncluded = true;
      }

      if (flopOddsText1.length > 0 && flopOddsText2.length > 0 && flopOddsText3.length > 0) {
        context.textAlign = "left";

        context.fillText(flopOddsText1, canvasXOffset + oddsX1 + 7, canvasYOffset + yOffset);
        context.fillText(flopOddsText2, canvasXOffset + oddsX2 + 7, canvasYOffset + yOffset);
        context.fillText(flopOddsText3, canvasXOffset + oddsX3 + 7, canvasYOffset + yOffset);

        context.textAlign = "right";

        context.fillText("Flop :", canvasXOffset + oddsX1, canvasYOffset + yOffset);
        context.fillText("Flop :", canvasXOffset + oddsX2, canvasYOffset + yOffset);
        context.fillText("Flop :", canvasXOffset + oddsX3, canvasYOffset + yOffset);

        yOffset += 30;
        oddsIncluded = true;
      }

      if (turnOddsText1.length > 0 && turnOddsText2.length > 0 && turnOddsText3.length > 0) {
        context.textAlign = "left";

        context.fillText(turnOddsText1, canvasXOffset + oddsX1 + 7, canvasYOffset + yOffset);
        context.fillText(turnOddsText2, canvasXOffset + oddsX2 + 7, canvasYOffset + yOffset);
        context.fillText(turnOddsText3, canvasXOffset + oddsX3 + 7, canvasYOffset + yOffset);

        context.textAlign = "right";

        context.fillText("Turn :", canvasXOffset + oddsX1, canvasYOffset + yOffset);
        context.fillText("Turn :", canvasXOffset + oddsX2, canvasYOffset + yOffset);
        context.fillText("Turn :", canvasXOffset + oddsX3, canvasYOffset + yOffset);

        yOffset += 30;
        oddsIncluded = true;
      }

      if (oddsIncluded) {
        yOffset += 30;
      }
    }
    // END - PLAYERS

    // BEGIN - BLINDS

    if (!!blinds && blinds.length > 0) {

      if (oddsIncluded) {
        yOffset -= 12;
      } else {
        yOffset += 10;
      }

      context.textAlign = "center";
      context.font = "24px Arial";

      const blindsX = 314 + canvasXOffset;
      const blindsY = canvasYOffset + yOffset;
      const blindsWidth = width - 40;

      context.fillText('Blinds : ' + blinds, blindsX, blindsY, blindsWidth);

      const blindsClickBoxX = blindsX - blindsWidth / 2;
      const blindsClickBoxY = blindsY - 30;
      const blindsClickBoxWidth = blindsWidth;
      const blindsClickBoxHeight = 46;

      props.addClickMap({
        id: 'blinds',
        x: blindsClickBoxX,
        y: blindsClickBoxY - 13,
        width: blindsClickBoxWidth - 8,
        height: blindsClickBoxHeight
      });
      
      if (redLines) {
        context.lineWidth = 2;
        context.strokeStyle = '#FF0000';
        context.rect(blindsClickBoxX, blindsClickBoxY, blindsClickBoxWidth, blindsClickBoxHeight);
        context.stroke();
      }

      yOffset += 46;
    } else if (!oddsIncluded) {
      yOffset += 16;
    } else {
      yOffset -= 4;
    }

    // END - BLINDS

    context.textAlign = "left";

    const detailsClickBoxX = canvasXOffset + 20;
    const detailsClickBoxY = canvasYOffset + yOffset - 24;
    const detailsClickBoxWidth = width - 40;

    textLines.forEach((line) => {
      console.log('LINE:' + line + ':');
      context.fillText(line, canvasXOffset + 20, canvasYOffset + yOffset, width - 40);
      yOffset += 30;
    });

    const detailsClickBoxHeight = yOffset - detailsClickBoxY - 20;

    props.addClickMap({
      id: 'details',
      x: detailsClickBoxX,
      y: detailsClickBoxY - 13,
      width: detailsClickBoxWidth - 8,
      height: detailsClickBoxHeight
    });

    if (redLines) {
      context.lineWidth = 2;
      context.strokeStyle = '#FF0000';
      context.rect(detailsClickBoxX, detailsClickBoxY, detailsClickBoxWidth, detailsClickBoxHeight);
      context.stroke();
    }

    // Website URL
    context.font = "18px Helvetica";
    context.fillText('www.PokerHandPost.com', canvasXOffset + 408, canvasYOffset + canvasHeight - 14);

    console.log("Start Timer - Update PNG");

    pngTimer = setTimeout(() => {
      console.log("Timer Pop - Update PNG");
      pngTimer = undefined;

      if (imageRef && imageRef.current) {
        const imageData = canvasRef.current.toDataURL('image/png');
        imageRef.current.src = imageData;

        if (props.updateImageData) {
          props.updateImageData(imageData);
        }

        if (props.share) {
          const ogImageElement = document.createElement('meta');
          ogImageElement.setAttribute('property', 'og:image');
          ogImageElement.content = imageData;
          const headElements = document.getElementsByTagName('head');
          headElements[0].appendChild(ogImageElement);
        }

        if (props.downloadLinkRef && props.downloadLinkRef.current) {
          props.downloadLinkRef.current.href = imageData;
        }
      }
    },500);
  };

  if (imgTimer) {
    console.log("Timer Clear - Update Image");
    clearTimeout(imgTimer);
  }

  console.log("Start Timer - Update Image");

  imgTimer = setTimeout(() => {
    console.log("Timer Pop - Update Image");
    imgTimer = undefined;
    updateImage();
  }, 500);

  return (
    <div>
    <canvas
    ref={canvasRef}
    height={canvasImageHeight}
    width={canvasImageWidth}
    style={{display:'none'}}
    />
    <img ref={imageRef} className='ShareImage' alt="SHARE" src={prefix + icon} onClick={props.shareImageOnClick}/>
    </div>
  );
}

export default ShareImage;
