import React from 'react';
import useStateWithLocalStorage from './useStateWithLocalStorage';
import Card from './Card';
import Trash from './img/trash.png';

import './PlayerForm.css'

function PlayerForm(props) {

  const playerKeyValue = props.playerKey;
  const [playerName, setPlayerName] = useStateWithLocalStorage(
    playerKeyValue + ':playerName', props.defaultPlayerName
  );
  const [playerPosition, setPlayerPosition] = useStateWithLocalStorage(
    playerKeyValue + ':playerPosition'
  );
  const [playerChips, setPlayerChips] = useStateWithLocalStorage(
    playerKeyValue + ':playerChips'
  );
  const [playerCard1, setPlayerCard1] = useStateWithLocalStorage(
    playerKeyValue + ':playerCard1'
  );
  const [playerCard2, setPlayerCard2] = useStateWithLocalStorage(
    playerKeyValue + ':playerCard2'
  );

  const onChangePlayerName = (event) => {
    setPlayerName(event.target.value);
    props.onTextChange();
  };
  const clearPlayerName = () => {
    setPlayerName('');
    props.onTextChange();
  };
  const onChangePlayerPosition = (event) => {
    setPlayerPosition(event.target.value);
    props.onTextChange();
  };
  const clearPlayerPosition = () => {
    setPlayerPosition('');
    props.onTextChange();
  };
  const onChangePlayerChips = (event) => {
    setPlayerChips(event.target.value);
    props.onTextChange();
  };
  const clearPlayerChips = () => {
    setPlayerChips('');
    props.onTextChange();
  };
  const clearPlayerCards = () => {
    console.log('CLEAR PLAYER CARDS');
    localStorage.setItem(playerKeyValue + ':playerCard1','CB');
    localStorage.setItem(playerKeyValue + ':playerCard2','CB');
    setPlayerCard1('CB');
    setPlayerCard2('CB');
    props.onUpdateCards();
  };

  var playerCard1Value = playerCard1 ? playerCard1 : 'CB';
  var playerCard2Value = playerCard2 ? playerCard2 : 'CB';

//playerPosition
  var positionSelector =
  (<select className='positionSelector' onChange={onChangePlayerPosition} value={playerPosition}>
    <option value="">- none -</option>
    <option value="D">Dealer Button</option>
    <option value="CO">Cutoff</option>
    <option value="HJ">Hi-Jack</option>
    <option value="LJ">Lo-Jack</option>
    <option value="UTG+3">UTG+3</option>
    <option value="UTG+2">UTG+2</option>
    <option value="UTG+1">UTG+1</option>
    <option value="UTG">Under the Gun</option>
    <option value="BB">Big Blind</option>
    <option value="SB">Small Blind</option>
  </select>);

  var textInputFields =
    (
      <table><tbody><tr><td>
      <table><tbody>
        <tr>
          <td style={{textAlign:'right'}}>PLAYER<br/>
          <input tabIndex='0' style={{textAlign:'right'}} className='InputField' value={playerName} type="text" onChange={onChangePlayerName} ref={props.displayNameInputRef}/></td>
          <td style={{verticalAlign:'bottom'}}><button className='TrashButton' onClick={clearPlayerName}>
            <img className='TrashImage' src={Trash} alt="CLEAR"/>
          </button></td>
        </tr>
        <tr>
          <td style={{textAlign:'right'}}>CHIPS<br/>
          <input tabIndex='1' style={{textAlign:'right'}} className='InputField' value={playerChips} type="text" onChange={onChangePlayerChips} ref={props.displayChipsInputRef}/></td>
          <td style={{verticalAlign:'bottom'}}><button className='TrashButton' onClick={clearPlayerChips}>
            <img className='TrashImage' src={Trash} alt="CLEAR"/>
          </button></td>
        </tr>
        <tr>
          <td style={{textAlign:'right'}}>POSITION<br/>
          {positionSelector}
          </td>
          <td style={{verticalAlign:'bottom'}}><button className='TrashButton' onClick={clearPlayerPosition}>
            <img className='TrashImage' src={Trash} alt="CLEAR"/>
          </button></td>
        </tr>
      </tbody></table>
      </td>
      <td style={{textAlign:'center'}}>
        <table style={{textAlign:'center', width:'100%'}}><tbody>
        <tr>
          <td style={{textAlign:'right', width:'100%'}}><button className='NoBorder' onClick={(event)=>{event.preventDefault();props.onClickCardChooser(playerKeyValue + ':playerCard1');}}>
            <Card>{playerCard1Value}</Card>
          </button></td>
          <td style={{textAlign:'left', width:'100%'}}><button className='NoBorder' onClick={(event)=>{event.preventDefault();props.onClickCardChooser(playerKeyValue + ':playerCard2');}}>
            <Card>{playerCard2Value}</Card>
          </button></td>
        </tr>
        <tr>
        <td colSpan="2">
          <button className='TrashButton' onClick={(event)=>{event.preventDefault();clearPlayerCards();}}>
            <img className='TrashImage' src={Trash} alt="CLEAR"/>
          </button>
        </td>
        </tr>

        </tbody></table>
      </td>
      </tr></tbody></table>
    );

  return (
    <div className='PlayerForm'>
      {textInputFields}
    </div>
  );
}

export default PlayerForm;
