const MAX_LINE = 57;

const getTextLines = (text) => {
  const words = text.split(' ');
  const lines = [];
  var lineCharCount = 0;
  var lineNum = 0;

  words.forEach((word) => {

    if (lines.length <= lineNum) {
      lines.push('');
    }

    if (word.includes('\n')) {

      const lineSplits = word.split('\n');

      for (var i = 0; i < lineSplits.length - 1; ++i) {
        const linePiece = lineSplits[i];

        if ((lineCharCount + linePiece.length) > MAX_LINE) {

          lines.push('');
          ++lineNum;
          lines[lineNum] = lines[lineNum] + linePiece + ' ';
          lineCharCount = linePiece.length + 1;

          lines.push('');
          ++lineNum;

        } else {

          lines[lineNum] = lines[lineNum] + linePiece + ' ';
          lines.push('');
          ++lineNum;
          lineCharCount = 0;
        }
      }

      const lastWord = lineSplits[lineSplits.length - 1];

      if (lastWord.length <= 0) {
        lines.push('');
        ++lineNum;
        lineCharCount = 0;
      } else {
        lines[lineNum] = lines[lineNum] + lastWord + ' ';
        lineCharCount = lineCharCount + lastWord.length + 1;
      }
      return;
    }

    if ((lineCharCount + word.length) > MAX_LINE) {
      ++lineNum;
      lines.push('');
      lines[lineNum] = lines[lineNum] + word + ' ';
      lineCharCount = word.length + 1;
    } else {
      lines[lineNum] = lines[lineNum] + word + ' ';
      lineCharCount = lineCharCount + word.length + 1;
    }
  });

  return lines;
}

export default getTextLines;
